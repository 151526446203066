import { Tab, Tabs }           from '@mui/material';
import Box                     from '@mui/material/Box';
import Link                    from '@mui/material/Link';
import Paper                   from '@mui/material/Paper';
import Typography              from '@mui/material/Typography';
import { Outlet, useLocation } from 'react-router-dom';
import { PermissionBoundary }  from '../Context/auth';

const links    = [
  { href: '/timeslots', label: 'View Timeslots' },
  { href: '/timeslots/new', label: 'New Timeslot' },
  // { href: '/students/bulk-add', label: 'Bulk Add Students' },
];
const activeId = (location) => {
  const num = links.findIndex((l) => l.href === location.pathname);
  return num === -1 ? 0 : num;
}


export default function Timeslot () {
  const location = useLocation();
  // const currentTab = routeMatch.pattern.path;

  // console.log({ location })

  return (
    <PermissionBoundary>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" paragraph>
          Timeslots
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeId(location)} component="nav" aria-label="Timeslot navigation">
            {links.map((l, i) => <Tab component={Link} key={i} value={i} {...l} />)}
          </Tabs>
        </Box>

        <Outlet />
      </Paper>
    </PermissionBoundary>
  );
}

import ClearIcon                                              from '@mui/icons-material/Clear';
import FilterAltIcon                                          from '@mui/icons-material/FilterAlt';
import SearchIcon                                             from '@mui/icons-material/Search';
import { InputAdornment, LinearProgress, TextField, Tooltip } from '@mui/material';
import Box                                                    from '@mui/material/Box';
import Button                                                 from '@mui/material/Button';
import IconButton                                             from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  DataGrid as MuiDataGrid,
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport
}                         from '@mui/x-data-grid';
import { Cat }            from 'react-kawaii';
import { MoreActionMenu } from './menuElements';

export function GridLoadingOverlay () {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export function NoRowsOverlay () {
  return (
    <GridOverlay>
      <Cat size={320} mood="excited" color="#596881" />
      <div>No Rows</div>
    </GridOverlay>
  )
}

export function FiltersToolbar (
  {
    onFilterClick,

    actions,

    textSearch,
    onTextSearchChange,
  }
) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {/*<GridToolbarFilterButton />*/}
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      { actions ? (
        <MoreActionMenu actions={actions}>
          <Button size={'small'} color={'primary'} startIcon={<MoreVertIcon color={'primary'} />}>
            Bulk Actions
          </Button>
        </MoreActionMenu>
      ) : null }

      <Box flexGrow={1} textAlign={'right'}>

        {onTextSearchChange ? (
          <TextField
            size="small"
            variant={'standard'}
            value={textSearch}
            onChange={e => onTextSearchChange(e.target.value)}
            sx={{ width: '200px' }}
            // label={'Search'}
            placeholder='Search'
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              endAdornment:   <InputAdornment position="end">
                                {textSearch ? (
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => onTextSearchChange('')}
                                    edge="end"
                                    size={'small'}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                ) : null}
                              </InputAdornment>,
            }}
            // margin={'dense'}
          />
        ) : null}

        <Tooltip title={'Filters'}>
          <IconButton color={'primary'} onClick={onFilterClick}>
            <FilterAltIcon fontSize={'small'} />
          </IconButton>
        </Tooltip>
      </Box>
    </GridToolbarContainer>
  );
}

export function DataGrid (
  {
    loading,
    columns,
    rows,
    rowCount,
    sortModel,
    setSort,

    setPagination,
    pageSize,

    ...rest
  }
) {
  return <MuiDataGrid
    loading={loading}
    autoHeight

    columns={columns}
    rows={rows || []}
    rowCount={rowCount || 0}

    filterMode="server"
    // filterModel={filter}
    // onFilterModelChange={setFilter}

    sortingMode="server"
    sortModel={sortModel}
    onSortModelChange={setSort}

    paginationMode="server"
    pageSize={pageSize}
    rowsPerPageOptions={[ 10, 15, 25, 50, 75, 100 ]}
    onPageChange={(newPage) => {
      setPagination(p => ({ ...p, page: newPage + 1 }))
    }}
    onPageSizeChange={(newLimit) => {
      setPagination(p => ({ ...p, page: 1, limit: newLimit }))
    }}

    disableColumnMenu
    disableColumnFilter

    {...rest}
  />
}

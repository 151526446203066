import { Tab, Tabs }           from '@mui/material';
import Box                     from '@mui/material/Box';
import Link                    from '@mui/material/Link';
import Paper                   from '@mui/material/Paper';
import Typography              from '@mui/material/Typography';
import { Outlet, useLocation } from 'react-router-dom';
import { PermissionBoundary }  from '../Context/auth';

const links    = [
  { href: '/students', label: 'View Students' },
  { href: '/students/new', label: 'New Student' },
  { href: '/students/bulk-add', label: 'Bulk Add Students' },
];
const activeId = (location) => {
  const num = links.findIndex((l) => l.href === location.pathname);
  return num === -1 ? 0 : num;
}


export default function Student () {
  const location = useLocation();

  // console.log({ location })

  return (
    <PermissionBoundary>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" paragraph>
          Students
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={activeId(location)} component="nav" aria-label="Student navigation">
            {links.map((l, i) => <Tab component={Link} key={i} {...l} />)}
          </Tabs>
        </Box>

        <Outlet />
      </Paper>
    </PermissionBoundary>
  );
}

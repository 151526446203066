import { FormControlLabel, FormGroup, Stack, Switch, TextField } from '@mui/material';
import Button                                                    from '@mui/material/Button';
import { useState }                                              from 'react';
import { graphql }                                               from '../../data/client';
import { GQL_QUERY_TEST_HBS_TEMPLATE }                           from '../../data/setting';
import { FormDialog, useDialogCtrl }                             from '../../lib/Form';
import Pre                                                       from '../../lib/Pre';
import useSubmitSnack                                            from '../../lib/snacks';
import Well                                                      from '../../lib/Well';
import emailExampleContext                                       from './email-example-context';

export function DisplayAsHBS ({ text: _text, onChange: _onChange }) {
  const [ text, setText ]               = useState(_text);
  const hasChanged                      = text !== _text;
  const { openDialog, getDialog, open } = useDialogCtrl();
  const previewCtrl                     = useDialogCtrl();
  const [ asHtml, setAsHtml ]           = useState(false);
  const snacks                          = useSubmitSnack();

  const onSubmit = () => {
    _onChange(text);
  }

  const onOpenDlg   = () => {
    openDialog({
      title: 'Example Email Context',
      children:
             (
               <Pre>
                 {JSON.stringify(emailExampleContext, null, 2)}
               </Pre>
             )
    })
  }
  const openPreview = async () => {
    let testHBS
    try {
      testHBS = await graphql({
        query:     GQL_QUERY_TEST_HBS_TEMPLATE,
        variables: {
          template: text,
          context:  emailExampleContext
        }
      });

    } catch (err) {
      snacks.error(err.message)
      console.error(err)
    }

    previewCtrl.openDialog({ previewText: testHBS.data.test.text });
  }

  return (
    <>
      {open ? getDialog({ width: '100%' }) : null}
      {previewCtrl.open ? (
        <FormDialog
          title="Template Preview"
          open={previewCtrl.open}
          onClose={previewCtrl.onClose}
          width="800px"
        >
          <FormGroup>
            <FormControlLabel control={<Switch value={asHtml} onClick={() => setAsHtml(b => !b)} />}
                              label={`Render as ${asHtml ? 'HTML' : 'text'}`} />
          </FormGroup>
          {asHtml ? (
            <Well sx={{ bgcolor: 'white' }} dangerouslySetInnerHTML={{ __html: previewCtrl.previewText }} />
          ) : (
             <Pre sx={{ whiteSpace: 'pre-line' }}>
               {previewCtrl.previewText}
             </Pre>
           )}

        </FormDialog>
      ) : null}

      <Stack spacing={2} sx={{ py: 2 }} direction="row">
        <Button variant="contained" disabled={!hasChanged} onClick={onSubmit}>Save Changes</Button>
        <Button variant="outlined" onClick={openPreview}>Preview</Button>
        <Button variant="outlined" onClick={onOpenDlg}>Example Student</Button>
      </Stack>


      <TextField
        multiline
        fullWidth
        value={text}
        onChange={e => setText(e.target.value)}
      />


      <Stack spacing={2} sx={{ py: 2 }} direction="row">
        <Button variant="contained" disabled={!hasChanged} onClick={onSubmit}>Save Changes</Button>
        <Button variant="outlined" onClick={openPreview}>Preview</Button>
        <Button variant="outlined" onClick={onOpenDlg}>Example Student</Button>
      </Stack>
    </>
  );
}

import Typography     from '@mui/material/Typography';
import { issuesLink } from '../config';

export default function IssueFooter () {
  return (
    <Typography variant={'body1'} paragraph color="textSecondary" align="center" maxWidth='sm' >
      To report issues, bugs, or feature requests, please submit a ticket <a
      href={issuesLink} target='_blank'>here.</a>
    </Typography>
  );
}

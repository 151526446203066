import { styled } from '@mui/material';
import Paper      from '@mui/material/Paper';

const Pre = styled((props) => <Paper elevation={0} variant="outlined" component={'pre'} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.grey['300'],
  padding:         theme.spacing(1)
}))


export default Pre;

import Typography from '@mui/material/Typography';

export default function Copyright () {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © UCI School of Education & UC Regents '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

import LaunchIcon                                            from '@mui/icons-material/Launch';
import { styled, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Link                                                  from '@mui/material/Link';
import Paper                                                 from '@mui/material/Paper';
import Table                                                 from '@mui/material/Table';
import TableContainer                                        from '@mui/material/TableContainer';
import Typography                                            from '@mui/material/Typography';
import ReactMarkdown                                         from 'react-markdown';
import rehypeRaw                                             from 'rehype-raw'
import remarkGfm                                             from 'remark-gfm'
import { visitParents }                                      from 'unist-util-visit-parents';



const LI     = styled('li')(({ theme }) => ({
  marginTop: theme.spacing(1)
}))
const Header = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1)
}))

const domainReg = new RegExp(`^https:\/\/${window.location.hostname.replace(/\./g, '\\.')}`, 'i');
const externalReg = /^https:\/\//i;

function MarkdownParagraph (props) {
  return <Typography>{props.children}</Typography>
}

const MarkdownHeading = ({ classes, ...props }) => {
  let variant;
  switch (props.level) {
    case 1:
      variant = 'h5';
      break;
    case 2:
      variant = 'h6';
      break;
    case 3:
      variant = 'subtitle1';
      break;
    case 4:
      variant = 'subtitle2';
      break;
    default:
      variant = 'h6';
      break;
  }
  return <Header className={classes.header} gutterBottom variant={variant}>{props.children}</Header>
};

const MarkdownListItem = ({ classes, ...props }) => {
  return (
    <LI>
      <Typography component="span">{props.children}</Typography>
    </LI>
  );
};

function MarkdownTable (props) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">{props.children}</Table>
    </TableContainer>
  );
}

function MarkdownTableCell (props) {
  return <TableCell><Typography>{props.children}</Typography></TableCell>
}

function MarkdownTableRow (props) {
  return <TableRow>{props.children}</TableRow>
}

function MarkdownTableBody (props) {
  return <TableBody>{props.children}</TableBody>
}

function MarkdownTableHead (props) {
  return <TableHead>{props.children}</TableHead>
}

const renderers = {

  heading:   MarkdownHeading,
  paragraph: MarkdownParagraph,
  link:      Link,
  listItem:  MarkdownListItem,
  table:     MarkdownTable,
  tableHead: MarkdownTableHead,
  tableBody: MarkdownTableBody,
  tableRow:  MarkdownTableRow,
  tableCell: MarkdownTableCell,
};

function exclaimPlugin () {
  return function wrapTextTransform (tree) {
    visitParents(tree, 'text', (node, ancestors) => {

      // console.log({ node, ancestors, test: ancestors.at(-1) })
      if (ancestors.at(-1).type === 'paragraph' && ancestors.at(-1).tagName !== 'exclaim') {
        const match = node.value.match(/^!!!\n(.*)\n!!!$/);
        if (match) {
          console.log({ node, match })
          node.type     = 'element';
          node.tagName  = 'exclaim';
          node.children = [ { type: 'text', value: match[1] } ];
        }
      }
    });
  };
}


const components = {
  //   code
  //   inline (boolean?) — set to true for inline code
  // className (string?) — set to language-js or so when using ```js
  h1:      ({ level, ...other }) => <Typography variant="h3" color="primary" {...other} />,
  h2:      ({ level, ...other }) => <Typography variant="h4" color="gray" pb={2} {...other} />,
  p:       (props) => <Typography paragraph {...props} />,
  span:    (props) => <Typography component="span" paragraph {...props} />,
  exclaim: (props) => <Typography paragraph color="red" {...props} />,
  a:       ({ children, ...props }) => {
    const otherProps = {};
    if (externalReg.test(props.href) && !domainReg.test(props.href)) {
      otherProps.target = '_blank';
      otherProps.rel = 'nofollow';
    }

    return (
      <Link component={'a'} {...props} {...otherProps}>
        {children}
        <LaunchIcon sx={{ ml: 0.5 }} fontSize="inherit" />
      </Link>
    )
  },
  // h1, h2, h3, h4, h5, h6
  //   level (number between 1 and 6) — heading rank
  //   input (when using remark-gfm)
  //   checked (boolean) — whether the item is checked
  //   disabled (true)
  //   type ('checkbox')
  // li
  //   index (number) — number of preceding items (so first gets 0, etc.)
  //   ordered (boolean) — whether the parent is an ol or not
  //   checked (boolean?) — null normally, boolean when using remark-gfm’s tasklists
  //   className (string?) — set to task-list-item when using remark-gfm and the item1 is a tasklist
  // ol, ul
  //   depth (number) — number of ancestral lists (so first gets 0, etc.)
  //   ordered (boolean) — whether it’s an ol or not
  //   className (string?) — set to contains-task-list when using remark-gfm and the list contains one or more tasklists
  // td, th (when using remark-gfm)
  //   style (Object?) — something like {textAlign: 'left'} depending on how the cell is aligned
  //   isHeader (boolean) — whether it’s a th or not
  // tr (when using remark-gfm)
  //   isHeader (boolean) — whether it’s in the thead or not
}

export const markdownPreviewProps = {
  remarkPlugins: [ remarkGfm ],
  rehypePlugins: [ rehypeRaw ],
  components
}

export default function Markdown ({ children, ...props }) {
  return <ReactMarkdown {...markdownPreviewProps} children={children} {...props} />;
}

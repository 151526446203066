export default function makeStore (name, debug) {
  return {
    get () {
      let value = window.localStorage.getItem(name);
      try {
        value = JSON.parse(value);
      } catch (err) {
        // do nothing
      }
      if (debug) console.log(`getToken ${name}`, value);
      return value;
    },
    set (value) {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      if (debug) console.log(`setting ${name}`, value);
      return window.localStorage.setItem(name, value);
    },
    reset () {
      if (debug) console.log(`resetting ${name}`);
      return window.localStorage.removeItem(name);
    }
  }
}

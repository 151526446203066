import { gql } from '@apollo/client';

export const GQL_QUERY_GET_ALL_TIMESLOTS = gql`
  query getTimeslots($filter: TimeslotFilterInput, $sort: SortInput, $page: PaginationInput) {
    listTimeslots(filter: $filter, sort: $sort, page: $page) {
      items {
        id
        day
        start
        end
        seats
        seatsAvailable
        seatsTaken
        hidden
      }
      meta { count pages }
    }
  }
`;


export const GQL_QUERY_GET_ALL_DAYS = gql`
  query getAllDays {
    listDays {
      items {
        day
        date
        timeslots {
          id
          day
          start
          end
          seats
          seatsAvailable
          seatsTaken
          hidden
        }
      }
    }
  }
`;

export const GQL_QUERY_GET_DAY_FOR_ROSTER = gql`
  query getDay ($date: Date!, $showHidden: Boolean) {
    day: getDay(date: $date, showHidden: $showHidden) {
      day
      date
      showHidden
      
      timeslots {
        id
        start
        end
        startTime
        endTime
        
        students {
          id
          uciStudentNum
          firstName
          lastName
          email
          phone
          program
          subject
        }
      }
    }
  }
`;

export const GQL_QUERY_GET_TIMESLOT = gql`
  query getTimeslot($id: ID!) {
    timeslot: getTimeslot(id: $id) {
      id

      day
      start
      end
      endTime
      startTime
      
      hidden

      cutoff
      cutoffDay
      duration

      seats
      seatsAvailable
      seatsTaken

      createdAt
      updatedAt

      students {
        id
        uciStudentNum
        lastName firstName
        email phone
        subject program
      }
    }
  }
`;

import { Stack }                          from '@mui/material';
import Button                             from '@mui/material/Button';
import MDEditor                           from '@uiw/react-md-editor';
import { useState }                       from 'react';
import rehypeSanitize                     from 'rehype-sanitize';
import { useDialogCtrl }                  from '../../lib/Form';
import Markdown, { markdownPreviewProps } from '../../lib/Markdown';
import useSubmitSnack                     from '../../lib/snacks';


export function DisplayAsMD ({ text: _text, onChange: _onChange }) {
  const [ text, setText ] = useState(_text);
  const hasChanged        = text !== _text;
  const [ preview, setPreview ] = useState(false);
  // const { openDialog, getDialog, open } = useDialogCtrl();
  const previewCtrl       = useDialogCtrl();
  const snacks            = useSubmitSnack();

  const onSubmit = () => {
    _onChange(text);
  }

  return (
    <>

      <Stack spacing={2} sx={{ py: 2 }} direction="row">
        <Button variant="contained" disabled={!hasChanged} onClick={onSubmit}>Save Changes</Button>
        <Button variant="outlined" onClick={() => setPreview(v => !v)}>Preview</Button>
        {/*<Button variant="outlined" onClick={onOpenDlg}>Example Student</Button>*/}
      </Stack>

      {preview ? (
        <Markdown>
          {text}
        </Markdown>
      ) : (
         <MDEditor
           value={text}
           onChange={setText}
           preview={'edit'}
           height={'100%'}
           previewOptions={{
             rehypePlugins: [ [ rehypeSanitize ] ],
             ...markdownPreviewProps
           }}
         />
       )}
      <Stack spacing={2} sx={{ py: 2 }} direction="row">
        <Button variant="contained" disabled={!hasChanged} onClick={onSubmit}>Save Changes</Button>
        <Button variant="outlined" onClick={() => setPreview(v => !v)}>Preview</Button>
        {/*<Button variant="outlined" onClick={onOpenDlg}>Example Student</Button>*/}
      </Stack>
    </>
  );
}

import { useQuery }                                                                                from '@apollo/client';
import KeyboardArrowDownIcon                                                                       from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon                                                                      from '@mui/icons-material/KeyboardArrowRight';
import EditIcon
                                                                                                   from '@mui/icons-material/Edit';
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import Box                                                                                         from '@mui/material/Box';
import Button
                                                                                                   from '@mui/material/Button';
import Divider                                                                                     from '@mui/material/Divider';
import Link
                                                                                                   from '@mui/material/Link';
import List                                                                                        from '@mui/material/List';
import Typography                                                                                  from '@mui/material/Typography';
import { useState }                                                                                from 'react';
import { Backpack }                                                                                from 'react-kawaii';
import { GQL_QUERY_LIST_SETTINGS }                                                                 from '../data/setting';



// const BorderLinearProgress = styled(LinearProgress, {
//   shouldForwardProp: prop => prop !== 'selected'
// })(({ theme, selected }) => ({
//   width:                                       '100%',
//   height:                                      theme.spacing(2),
//   borderRadius:                                5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]:         {
//     borderRadius:    5,
//     backgroundColor: selected ? theme.palette.success.main : theme.palette.primary.main,
//   },
// }));

export default function ListSettings () {
  const { data, loading, error } = useQuery(GQL_QUERY_LIST_SETTINGS);

  if (error) {
    console.error(error.stack);
    return null;
  }

  const { items: settings = [] } = data ? data.listSettings : {};
  // const settings = [];
  console.log('settings', settings);

  if (loading) {
    return (
      <Box textAlign="center">
        <Typography variant="h5">
          Loading ...
        </Typography>
        <Backpack size={200} color="pink" />
      </Box>
    )
  }

  return (
    <List>
      {settings.sortBy('label').map(s => <SettingListItem key={s.key} setting={s} />).joinElements(<Divider />)}
    </List>
  )

}

function SettingListItem ({ setting }) {
  const { key, label, type } = setting;
  const [ show, setShow ]    = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setShow(!show)}>
        <ListItemIcon>
          {show ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
      <Collapse in={show}>
        <List sx={{ pl: 7, pt: 0 }}>
          {/*<ListItem>*/}
          {/*  <ListItemText primary={key} secondary="Key" />*/}
          {/*</ListItem>*/}
          <ListItem>
            <ListItemText primary={type} secondary="Data Type" />
            <ListItemSecondaryAction>
              <Button variant={'outlined'} startIcon={<EditIcon />} component={Link} href={`/settings/${key}`}>Edit</Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}

import styled            from '@emotion/styled';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HomeIcon          from '@mui/icons-material/Home';
import LoginIcon         from '@mui/icons-material/Login';
import MenuIcon          from '@mui/icons-material/Menu';
import SchoolIcon        from '@mui/icons-material/School';
import { Tab }           from '@mui/material';
import Box               from '@mui/material/Box';
import Button            from '@mui/material/Button';
import IconButton        from '@mui/material/IconButton';
import Link              from '@mui/material/Link';
import List              from '@mui/material/List';
import Menu              from '@mui/material/Menu';
import MenuItem          from '@mui/material/MenuItem';
import Typography        from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';

import { cloneElement, useState }               from 'react';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_HREF, useAuth }                  from '../Context/auth';
import { handleLoginClick }                     from '../Context/auth/useRedirectToken';
import { GQL_QUERY_REDIRECT_TOKEN }             from '../data/auth';
import { graphql }                              from '../data/client';
import UserIcon                                 from './UserIcon';

const Root    = styled('nav')(({ theme }) => ({
  display: 'flex',
}));
const NavList = styled(List)(({ theme }) => ({}));


function LinkTab (props) {
  return (
    <Tab
      component={Link}
      {...props}
    />
  );
}

// export function Nav () {
//   const { isLoggedIn, logout } = useAuth();
//   // const { data: tally } = useLayout();
//   //
//   // const {
//   //         openPurchases,
//   //         orderedItems,
//   //         inventoriableAssets
//   //       } = tally;
//
//   return (
//     <Root>
//       <Box sx={{ flexGrow: 1 }} />
//
//       {isLoggedIn ? (
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           aria-label="icon position tabs example"
//         >
//           <LinkTab icon={<ShoppingCartIcon />} href={'/'} iconPosition='start' label="Register" />
//           <LinkTab icon={<ShoppingCartIcon />} href={'/login'} iconPosition="start" label="Login" />
//         </Tabs>
//       ) : (
//         <>
//           <NavButton component={Link} href="/purchase" startIcon={<ShoppingCartIcon />}>
//             Purchases
//           </NavButton>
//           <NavButton component={Link} href="/receiving" startIcon={<LocalShippingIcon />}>
//             Receiving
//           </NavButton>
//         </>
//       )}
//
//     </Root>
//   );
// }



const pages       = [
  { text: 'Register', Icon: HomeIcon, href: '/', loggedIn: null },
  { text: 'Login', Icon: LoginIcon, component: <LoginLink />, loggedIn: false },
  { text: 'Timeslots', Icon: CalendarTodayIcon, href: '/timeslots', loggedIn: true },
  { text: 'Students', Icon: SchoolIcon, href: '/students', loggedIn: true },
  { text: 'Settings', Icon: SettingsIcon, href: '/settings', loggedIn: true },
];
const filterPages = (pages, isLoggedIn = false) => {
  return pages.filter(p => p.loggedIn === null || p.loggedIn === isLoggedIn);
}

export function Nav () {
  const { isLoggedIn, logout }          = useAuth();
  const [ anchorElNav, setAnchorElNav ] = useState(null);

  const handleOpenNavMenu  = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical:   'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical:   'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {filterPages(pages, isLoggedIn).map((page) => (
            <MenuItem component={Link} key={page.text} href={page.href} onClick={handleCloseNavMenu}>
              <page.Icon />
              <Typography component="span" sx={{ pl: 1 }}>{page.text}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}
      >
        MAT Interviews
      </Typography>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
        {filterPages(pages, isLoggedIn).map(({ component, ...page }) => {
          if (component) {
            return cloneElement(component, { ...page, handleCloseNavMenu, key: page.text })
          }
          return (
            <Button
              component={Link}
              key={page.text}
              href={page.href}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page.text}
            </Button>
          )
        })}
      </Box>

      <Box sx={{ flexGrow: 0, pr: 1 }}>
        {isLoggedIn
         ? <UserIcon />
         : <Box width="44px" />
        }
      </Box>
    </>
  );
};


function LoginLink ({ link, text, handleCloseNavMenu }) {
  const { pathname } = useLocation();

  return (
    <Button
      onClick={() => handleLoginClick(pathname)}
      sx={{ my: 2, color: 'white', display: 'block' }}
    >
      {text}
    </Button>
  )
}

import Paper            from '@mui/material/Paper';
import Container        from '@mui/material/Container';
import Typography       from '@mui/material/Typography';
import Button           from '@mui/material/Button';
import TextField        from '@mui/material/TextField';
import Link             from '@mui/material/Link';
import { useLocation }  from 'react-router-dom';
import { grey as gray } from '@mui/material/colors';
import Box              from '@mui/material/Box';


export function getDomain () {
  const l = window.location;
  return `${l.protocol}//${l.host}/`;
}

export default function Login () {
  const location = useLocation();

  // console.log('loginEl', location.state);
  const authMessage = location.state && location.state.authErrorMessage;

  return (
    <Box sx={{ backgroundColor: gray[200], height: '100vh' }}>

      <Container maxWidth='sm' sx={{my: 5, mx: 'auto'}}>
        <Paper elevation={1} sx={{p: 3}}>
          <Typography sx={{mb:3}} variant='h4'>Login</Typography>

          {authMessage ? (
            <Typography variant={'subtitle1'} sx={{color: 'warning.main'}}>{authMessage}</Typography>
          ) : null}

          <form style={{width:'100%'}} method="post" action="https://login.uci.edu/ucinetid/webauth">
            <input type="hidden" name="referer" value="" />
            <input type="hidden" name="return_url" value={getDomain()} />
            <input type="hidden" name="info_text" value="" />
            <input type="hidden" name="info_url" value="" />
            <input type="hidden" name="submit_type" value="" />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus
              id="ucinetid"
              label="UCINetID"
              name="ucinetid"
              autoComplete="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              name='login_button'
              value='Login'
              sx={{mt: 3, mx: 0, mb: 2}}
            >
              Login
            </Button>
            <Link href="https://activate.uci.edu/cgi-bin/pwreset.cgi" variant="body2">
              Forgot password?
            </Link>
          </form>

        </Paper>
      </Container>
    </Box>
  );
};

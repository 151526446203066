import { createContext, useContext, useEffect, useState }           from 'react';
import { useLocation, useNavigate, useSearchParams }                from 'react-router-dom';
import { getProfileQuery }                                          from '../../data/auth';
// import { apiServer, samlServer }                                    from '../../utils/config';
import { getFlash, resetFlash, setFlash }                           from './flash';
import { getToken, LOCALSTORAGE_TOKEN_FIELD, resetToken, setToken } from './token';
import { apiServer, samlServer }                                    from '../../config';
import { handleLoginClick }                                         from './useRedirectToken';


export const LOGIN_HREF      = `${samlServer}/login`;
export const PROFILE_ADDRESS = `${apiServer}/profile`


// export const LOGIN_HREF      = `${samlServer}/login`
// export const PROFILE_ADDRESS = `${apiServer}/profile`

export function throwAuthErrorEvent (message) {
  const e = new CustomEvent('AuthError', { detail: { message } });
  window.dispatchEvent(e);
}

export function AuthGateway ({ login, loading: loadingEl, children }) {
  const { isLoggedIn, loading } = useAuth();

  // console.log('authGateway', {
  //   isLoggedIn, loading,
  // })

  if (loading) {
    return loadingEl;
  }
  // if (!isLoggedIn) {
  //   return login;
  // }
  return children;

}

export function PermissionBoundary ({ children }) {
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();

  // console.log('login check', isLoggedIn);
  if (!isLoggedIn) {
    console.log('not logged in');

    return handleLoginClick(pathname);
  }

  return children;
}


const AuthContext = createContext({
  loading:    true,
  isLoggedIn: false,
  token:      '',
  ucinetid:   ''
});

export const useAuth = () => useContext(AuthContext);


export function AuthProvider ({ children, onLogout }) {
  const navigate                          = useNavigate();
  const location                          = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ loading, setLoading ]           = useState(true);
  const [ error, setError ]               = useState(false);
  const [ user, setUser ]                 = useState({ ucinetid: null, firstName: '', lastName: '', isAdmin: false });
  const token                             = getToken();

  const state = {
    loading:    loading,
    isLoggedIn: !!user.ucinetid,
    ucinetid:   user.ucinetid,
    error,
    user
  };



  const getProfile = async () => {
    try {
      // console.log('getting profile')
      const { data } = await getProfileQuery();
      console.log('profile gotten', data)
      // console.log('profile:', data);
      setUser(data.profile.user);
    } catch (e) {
      console.warn('problem authenticating:', e.response);
      logout(e.response && e.response.data)
    }
  };

  const checkAuthError = () => {
    const redirectAuthError = searchParams.get('auth-error');

    if (redirectAuthError) {
      console.warn(`Detected Auth Error on return from login,`, redirectAuthError)
      setError(redirectAuthError)
      resetToken();
      setLoading(false);

      navigate('/', {
        replace: true,
        state:   {
          authErrorMessage: redirectAuthError === 'nonuser'
                            ? 'Your UCINetID has not been authorized for this service. If you think this is in error, please contact tech support'
                            : 'An Unknown error occurred while signing in. Please contact Rhett Lowe at rhett@uci.edu'
        }
      })

      return true;
    }

    return false;
  };

  const init = async () => {
    if (checkAuthError()) {
      return;
    }

    const token       = getToken();
    const searchToken = searchParams.get(LOCALSTORAGE_TOKEN_FIELD);

    // console.log(token, searchToken)

    // new token
    if (searchToken) {
      console.log('found query auth token', searchToken);
      setToken(searchToken)
      await getProfile(searchToken);

      const flash = getFlash();
      if (flash) {
        resetFlash();

        if (flash.pathname) {
          navigate(flash.pathname, {
            replace: true,
            state:   flash.state || {},
          });
          setLoading(false);
          return;
        }
      }

      // remove the auth_token part in the search
      navigate(location.pathname || '/', { replace: true });

    } else if (token) { // existing token
      await getProfile(token);
    }

    setLoading(false);
  };

  useEffect(() => {
    console.log('running auth init');
    init();
    return () => null;
  }, [])


  function logout (reason) {

    let message = undefined;
    // eslint-disable-next-line default-case
    switch (reason) {
      case 'TokenExpiredError':
        message = 'Authentication Expired: Please re-login.';
        break;
      case 'JsonWebTokenError':
        message = 'Invalid Authentication Token';
        break;
      case 'NotBeforeError':
        message = 'Authentication Token not yet valid.';
        break;
    }

    navigate('/', {
      replace: true,
      state:   {
        authErrorMessage: message || reason,
        prevPath:         location.pathname
      }
    });
    console.log('ping');
    setFlash(location);
    setUser({ ucinetid: null, roles: [] });
    resetToken();

    if (typeof onLogout === 'function') {
      onLogout();
    }
  }

  const authErrorHandler = event => {
    console.log('Event Logged out user', event);
    logout(event.detail && event.detail.message);
  }


  useEffect(() => {
    window.addEventListener('AuthError', authErrorHandler, { once: true });

    return () => {
      window.removeEventListener('AuthError', authErrorHandler);
    }
  }, [ logout, token ]);


  return (
    <AuthContext.Provider value={{ ...state, logout }}>{children}</AuthContext.Provider>
  );
}

import { useQuery }                                                                        from '@apollo/client';
import FormatListBulletedIcon                                                              from '@mui/icons-material/FormatListBulleted';
import MoreVertIcon                                                                        from '@mui/icons-material/MoreVert';
import PeopleAltIcon                                                                       from '@mui/icons-material/PeopleAlt';
import { Badge, ListItemButton, ListItemIcon, ListItemText }                               from '@mui/material';
import Box                                                                                 from '@mui/material/Box';
import Divider                                                                             from '@mui/material/Divider';
import IconButton                                                                          from '@mui/material/IconButton';
import Link                                                                                from '@mui/material/Link';
import List                                                                                from '@mui/material/List';
import Typography                                                                          from '@mui/material/Typography';
import formatDate                                                                          from '../utils/formatDate';
import Papa                                                                                from 'papaparse';
import { Backpack }                                                                        from 'react-kawaii';
import { graphql }                                                                         from '../data/client';
import { GQL_MUTATION_SET_TIMESLOT, GQL_QUERY_GET_ALL_DAYS, GQL_QUERY_GET_DAY_FOR_ROSTER } from '../data/timeslot';
import { MoreActionMenu }                                                                  from '../lib/menuElements';
import VisibilityOffIcon                                                                   from '@mui/icons-material/VisibilityOff';
import VisibilityIcon                                                                      from '@mui/icons-material/Visibility';
import downloadCsvToUser from '../utils/downloadCsvToUser';


// const BorderLinearProgress = styled(LinearProgress, {
//   shouldForwardProp: prop => prop !== 'selected'
// })(({ theme, selected }) => ({
//   width:                                       '100%',
//   height:                                      theme.spacing(2),
//   borderRadius:                                5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]:         {
//     borderRadius:    5,
//     backgroundColor: selected ? theme.palette.success.main : theme.palette.primary.main,
//   },
// }));

export default function ListTimeslots () {
  const { data, loading, error, refetch } = useQuery(GQL_QUERY_GET_ALL_DAYS);

  if (error) {
    console.error(error.stack);
    return null;
  }

  const { items: days = [] } = data ? data.listDays : {};
  // const days = [];
  // console.log('days', days);

  if (loading) {
    return (
      <Box textAlign="center">
        <Typography variant="h5">
          Loading ...
        </Typography>
        <Backpack size={200} color="pink" />
      </Box>
    )
  }

  return days.map(d => (
    <ShowDay
      key={d.day}
      day={d.day}
      date={d.date}
      times={d.timeslots}
      onRefetch={refetch}
    />
  ));
}


function ShowDay ({ day, date, times, onRefetch }) {
  const numberOfPeople = times.reduce((sum, next) => sum + next.seatsTaken, 0);

  console.log({ day, numberOfPeople, date, /*time: date && date.getTime()*/ })

  const _updateVisibility = (isHidden) => {
    const proms = times.map(t => {
      return graphql({
        mutation: GQL_MUTATION_SET_TIMESLOT,
        variables: { timeslot: { id: t.id, hidden: isHidden } }
      })
    })
    Promise.all(proms).then(() => onRefetch())
  }

  const setVisibilityOff = () => {
    _updateVisibility(true);
  }
  const setVisibilityOn = () => {
    _updateVisibility(false);
  }
  const downloadRosterCSV = () => {
    // downloadCsvToUser(data, name)
    graphql({
      query: GQL_QUERY_GET_DAY_FOR_ROSTER,
      variables: { date, showHidden: true }
    })
      .then(({ data }) => {
        const day = data.day;
        const columns = {
          uciStudentNum: 'Student #',
          firstName: 'First',
          lastName: 'Last',
          email: 'Email',
          phone: 'Phone',
          program: 'Program',
          subject: 'Subject',
          day: 'Interview Day',
          startTime: 'Interview Time',
        };
        let rows = [];
        day.timeslots.forEach(ts => {
          ts.students.forEach(s => {
            rows.push({
              uciStudentNum: s.uciStudentNum,
              firstName: s.firstName,
              lastName: s.lastName,
              email: s.email,
              phone: s.phone,
              program: s.program,
              subject: s.subject,
              day: day.day,
              startTime: ts.startTime
            });
          });
        });

        const csv = Papa.unparse(rows, { columns });
        downloadCsvToUser(csv, `Roster ${formatDate(day.date, 'yyyy-MM-dd')}.csv`)
      })

  }

  return (
    <Box sx={{ mb: 3 }}>
      <Box display="flex">
        <Typography variant="h5" flexGrow={1}>
          {/*{formatDate(date, 'EEEE, MMMM do, yyyy')}*/}
          {formatDate(date, 'EEEE, MMMM do, yyyy')}
        </Typography>
        <MoreActionMenu
          actions={[
            { text:      'Roster',
              icon:      <FormatListBulletedIcon />,
              onClick: downloadRosterCSV
            },
            { text: `${numberOfPeople} People`, textOnly: true, disabled: true },
            { divider: true },
            { text: 'Hide All', icon: <VisibilityOffIcon />, onClick: setVisibilityOff },
            { text: 'Show All', icon: <VisibilityIcon />, onClick: setVisibilityOn },
          ]}
          anchorOrigin={{
            vertical:   'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical:   'top',
            horizontal: 'right',
          }}
        >
          <IconButton color="inherit">
            <MoreVertIcon />
          </IconButton>
        </MoreActionMenu>
      </Box>
      <Divider sx={{ mb: 1 }} />

      <List>
        {times.map(t => <ShowTime key={t.id} slot={t} />)}
      </List>
    </Box>
  );
}


function ShowTime ({ slot: { id, start, end, seatsAvailable, seatsTaken, seats, hidden } }) {
  const startTime = formatDate(start, 'h:mm');
  const endTime   = formatDate(end, 'h:mm');

  return (
    <ListItemButton component={Link} href={`/timeslots/${id}`} sx={{ display: 'flex' }}>
      <ListItemIcon>
        <Badge badgeContent={seatsTaken} color="secondary">
          <PeopleAltIcon color="action" />
        </Badge>
      </ListItemIcon>
      <ListItemText primary={`${startTime}-${endTime}`} sx={{ flexGrow: 1 }} />
      { hidden ? <VisibilityOffIcon /> : null }
    </ListItemButton>
  );
}

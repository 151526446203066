import { forwardRef }         from 'react';
import { Link as RouterLink, NavLink as RouterNavLink, useLocation } from 'react-router-dom';

function rectifyTo (href, to) {
  if (href) {
    return objOrString(href);
  }
  if (to) {
    return objOrString(to);
  }
  return {};
}

export default forwardRef(
  function LinkBehavior (props, ref) {
    const location = useLocation();
    const { href, to, nav, onClick, state = {}, ...other } = props;
    // Map href (Material-UI) -> to (react-router)

    let toObj = rectifyTo(href, to);
    toObj.state = {
      prevPath: location.pathname,
      ...state,
      ...(toObj.state || {}),
    }

    if (onClick) {
      toObj.onClick = onClick;
      // toObj.to = '';
    }

    // if (nav)
    // console.log(props)

    if (nav) {
      return <RouterNavLink
        ref={ref}
        replace={false}
        className={({isActive}) => isActive ? 'Mui-selected' : undefined}
        {...toObj}
        {...other}
      />
    } else {
      return <RouterLink ref={ref} replace={false} {...toObj} {...other} />;
    }
  }
)





function objOrString(toCheck) {
  if (!toCheck) {
    return null;
  }

  if (typeof toCheck === 'object') {
    return toCheck;
  }
  return {
    to: toCheck,
    href: toCheck
  }
}

import { useQuery }                                           from '@apollo/client';
import { ListItem, ListItemText }                             from '@mui/material';
import List                                                   from '@mui/material/List';
import { useParams }                                          from 'react-router-dom';
import { graphql }                                            from '../data/client';
import { GQL_MUTATION_UPDATE_SETTING, GQL_QUERY_GET_SETTING } from '../data/setting';
import useSubmitSnack                                         from '../lib/snacks';
import { DisplayAsHBS }                                       from './lib/DisplayAsHBS';
import { DisplayAsMD }                                        from './lib/DisplayAsMD';
import { DisplayAsText }                                      from './lib/DisplayAsText';
import { LoadingBackpack }                                    from './lib/LoadingBackpack';


export default function ViewSetting () {
  const { key }                           = useParams();
  const { data, loading, error, refetch } = useQuery(GQL_QUERY_GET_SETTING, { variables: { key } });
  const snacks                            = useSubmitSnack();

  if (loading) {
    return <LoadingBackpack />
  }

  const setting                         = data ? data.getSetting : {};
  const { label, type, value, isAdmin } = setting;

  const onChange = (newValue) => {
    snacks.start('Saving changes...');
    graphql({
      mutation:  GQL_MUTATION_UPDATE_SETTING,
      variables: {
        setting: {
          key:   setting.key,
          value: newValue,
        }
      }
    })
      .then(() => {
        snacks.success();
        refetch();
      })
      .catch(snacks.catchError())
  }

  let displayByType;
  switch (type) {
    case 'hbs-template':
      displayByType = <DisplayAsHBS text={value} onChange={onChange} />;
      break;
    case 'markdown':
      displayByType = <DisplayAsMD text={value} onChange={onChange} />;
      break;
    default:
      displayByType = <DisplayAsText text={value} onChange={onchange} />;
  }

  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary={label} secondary="Label" />
        </ListItem>
        <ListItem>
          <ListItemText primary={type} secondary="Data Type" />
        </ListItem>
      </List>

      {displayByType}

    </>
  )
}




import { useSearchParams } from 'react-router-dom';
import _noop               from '../utils/noop';

const noop = (data) => {
  _noop(data);
  return data;
}

export default function useSearchParamsSync (
  {
    defaultState = {}, // default multi-level object
    translateStateToParams = noop, // converts multi-level objects into flat object for search-parsing
    translateParamsToState = noop, // converts search params to multi-level object
  }
) {
  const [ _searchParams, setSearchParams ] = useSearchParams();
  const searchParams                       = parseForArrays(_searchParams);

  const flatDefault = translateStateToParams(defaultState);

  const currentState = objectEach({ ...flatDefault, ...searchParams }, convert);
  const searchState  = translateParamsToState(currentState);

  // console.log({
  //   searchParams,
  //   flatDefault,
  //   currentState,
  //   searchState
  // })

  function updateSearchParams (data, { replace = true, state } = {}) {
    const flatData = translateStateToParams(data);
    // console.log('updating', flatData)

    let out = {};
    Object.getOwnPropertyNames(flatData)
      .forEach(k => {
        const v = flatData[k];

        if (v instanceof Date) {
          out[k] = v.toISOString();
        } else if (Array.isArray(v) && !arrayEquals(flatDefault[k], v)) {
          out[`${k}[]`] = v;
        } else if (flatDefault[k] !== v && v !== '') {
          out[k] = v;
        }

      })

    setSearchParams(out, { replace, state })
  }

  return {
    searchState,
    searchParams,
    updateSearchParams
  };
}


// function mapFields (fieldsObj) {
//   let values    = {};
//   let structure = {};
//
//   Object.getOwnPropertyNames(fieldsObj)
//     .forEach(k => {
//       const v = fieldsObj[k];
//
//       if (typeof v === 'object' && !Array.isArray(v)) {
//         Object.getOwnPropertyNames(v).forEach(kk => {
//           structure[kk] = k
//           values[kk]    = v[kk];
//         });
//       } else {
//         values[k] = v;
//       }
//     })
//
//   return { values, structure };
// }

const isoReg = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
function convert (data) {
  if (data === 'true') return true;
  if (data === 'false') return false;
  if (isoReg.test(data)) return new Date(data);
  if (!isNaN(Number(data))) return Number(data);

  return data;
}



function parseForArrays (urlSearchParams) {
  // console.log('parseForArray', Array.from(urlSearchParams.entries()))
  let out = {};

  for (let [ k, v ] of urlSearchParams) {
    if (/\[]$/.test(k)) {
      k = k.replace(/\[]$/, '');
      if (!out[k]) {
        out[k] = [];
      }
      out[k].push(v);
    } else {
      out[k] = v;
    }
  }

  return out;
}

function parseForArraysEntities (urlSearchParams) {
  const out = parseForArrays(urlSearchParams);
  return Object.getOwnPropertyNames(out)
    .map(k => [ k, out[k] ])
    ;
}

function arrayEquals (arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }

  if (
    arr1.filter(a => !arr2.includes(a)).length
    || arr2.filter(a => !arr1.includes(a)).length
  ) {
    return false;
  }

  return true;
}

function objectEach (obj, func = _noop) {
  return Object.getOwnPropertyNames(obj)
    .reduce((o, k) => ({ ...o, [k]: func(obj[k]) }), {});
}

import { client as apollo } from '../Context/Apollo';

export function graphql ({mutation, query, variables = {}} = {}) {
  if (mutation) {
    return apollo.mutate({
      mutation, variables
    });
  }
  if (query) {
    return apollo.query({
      query, variables
    });
  }
  throw new Error(`No query or mutation provided. Please provide either.`);
}

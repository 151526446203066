import Paper                   from '@mui/material/Paper';
import Typography              from '@mui/material/Typography';
import { Outlet, useLocation } from 'react-router-dom';
import { PermissionBoundary }  from '../Context/auth';


export default function Settings () {
  const location = useLocation();
  // const currentTab = routeMatch.pattern.path;

  // console.log({ location })

  return (
    <PermissionBoundary>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" paragraph>
          Settings
        </Typography>

        <Outlet />

      </Paper>
    </PermissionBoundary>
  );
}

import { useTheme }   from '@mui/material';
import Box            from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress'
import Typography     from '@mui/material/Typography';
import { Cat }        from 'react-kawaii';

export default function Loading () {
  const theme = useTheme();

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent="center" alignItems="center" height={'100vh'}>

      <Box flexGrow={1} display={'flex'} alignItems={'center'}>
        <Cat size={250} mood="excited" color={theme.palette.primary.light} />
      </Box>

      <Box paddingBottom={5}>
        <Typography variant="h6" textAlign={'center'}>
          One moment please
        </Typography>

        <LinearProgress color="secondary" />
      </Box>

    </Box>
  );
}

import { useQuery }                                                               from '@apollo/client';
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, styled, useTheme } from '@mui/material';
import Box                                       from '@mui/material/Box';
import Divider                                   from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import List                                      from '@mui/material/List';
import Paper                                     from '@mui/material/Paper';
import Typography                                from '@mui/material/Typography';
import useMediaQuery                             from '@mui/material/useMediaQuery';
import { Backpack }                              from 'react-kawaii';
import { GQL_QUERY_FUTURE_DAYS }                 from '../../data/register';
import { ErrorBlurb }                            from '../../other-pages/Error';
import formatDate       from '../../utils/formatDate';


const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: prop => prop !== 'selected'
})(({ theme, selected }) => ({
  width:                                       '100%',
  height:                                      theme.spacing(2),
  borderRadius:                                5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]:         {
    borderRadius:    5,
    backgroundColor: selected ? theme.palette.success.main : theme.palette.primary.main,
  },
}));

export default function SelectTimeslot ({ sx = {}, selectedTimeslot, onChange }) {
  const { loading, error, data } = useQuery(GQL_QUERY_FUTURE_DAYS);

  if (error) {
    console.error(error.stack);
    return (
      <Paper sx={{ p: 3, ...sx }}>
        <ErrorBlurb align="left" err={error} />
      </Paper>
    );
  }

  // console.log(data)

  const days = !loading && data ? data.futureDays.items : [];

  return (
    <Paper sx={{ p: 3, ...sx }}>
      <Typography variant="h4" color="primary" paragraph>
        Interview Date
      </Typography>

      <Typography paragraph>
        Please select one of the following interview slots. Please note interview submissions are final and cannot be
        edited once submitted.
      </Typography>

      <Divider sx={{ my: 4 }} />

      {loading
       ? (
         <Box textAlign="center">
           <Typography variant="h5">
             Loading ...
           </Typography>
           <Backpack size={200} color="pink" />
         </Box>
       ) : days.map(d => (
          <SelectDay
            key={d.day}
            day={d.day}
            date={d.date}
            times={d.timeslots}
            selected={selectedTimeslot}
            onChange={onChange}
          />
        ))
      }

    </Paper>
  );
};


function SelectDay ({ day, date, times, selected, onChange }) {

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h5">
        {formatDate(date, 'EEEE, MMMM do, yyyy')}
      </Typography>
      <Divider sx={{ mb: 1 }} />

      <List>
        {times.map(t => <SelectTime key={t.id} slot={t} isSelected={selected === t.id} onClick={onChange} />)}
      </List>
    </Box>
  );
}

function SelectTime ({ slot: { id, start, end, seatsAvailable, taken, seats }, isSelected, onClick }) {
  const isXS      = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const now       = new Date();
  const startTime = formatDate(start, 'h:mm aaa');
  const endTime   = formatDate(end, 'h:mm aaa');

  const remaining = `${seatsAvailable} / ${seats}`;
  const zone = isXS ? formatDate(start, 'zzz') : 'Pacific';

  return (
    <ListItemButton onClick={() => onClick(id)} disabled={!seatsAvailable}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={isSelected}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': id }}
        />
      </ListItemIcon>
      <Box flexGrow={1} display="flex" flexWrap="wrap">
        <ListItemText id={id} primary={`${startTime} - ${endTime} (${zone})`} sx={{ w: 0.5 }} />
        <ListItemText id={id} primary={`${remaining} ${isXS ? 'Avail.' : 'Seats Available'}`}
                      sx={{ w: 0.5, textAlign: 'right' }} />

        <BorderLinearProgress variant="determinate" value={(seatsAvailable / seats) * 100} selected={isSelected} />
      </Box>
    </ListItemButton>
  );
}

import addDays              from 'date-fns/addDays';
import formatDate           from '../../utils/formatDate';
import setHours           from 'date-fns/setHours';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import startOfDay           from 'date-fns/startOfDay';


const now = startOfDay(new Date());
const twoWeeks = addDays(now, 14);
const start = setHours(twoWeeks, 10);
const end = setHours(twoWeeks, 14);


const exampleContext = {
  student: {
    id: 'qwertyuio567890',
    uciStudentNum: '1234567890',
    firstName: 'David',
    lastName: 'Lim',
    phone: '800-555-1234',
    email: 'dklim@uci.edu',

    program: 'Single Subject',
    subject: 'Mathematics',

    timeslotId: '12345678qwertyuio'
  },
  timeslot: {
    id: '12345678qwertyuio',

    day: formatDate(start, 'MM/dd/yyyy'),
    start,
    end,
    startTime: formatDate(start, 'hh:mm aa'),
    endTime: formatDate(end, 'hh:mm aa'),
    duration: formatDistanceStrict(end, start),

    cutoff: twoWeeks,
    cutoffDay: formatDate(twoWeeks, 'MM/dd/yyyy'),

    seats: 20,
    seatsTaken: 5,
    seatsAvailable: 15,

  },


  invite: {
    id: 'asdoihasdoih3453453',

    key: 'ABC123',
    email: 'dklim@uci.edu',

    studentId: 'qwertyuio567890',
    student: {
      id: 'qwertyuio567890',
      uciStudentNum: '1234567890',
      firstName: 'David',
      lastName: 'Lim',
      phone: '800-555-1234',
      email: 'dklim@uci.edu',

      program: 'Single Subject',
      subject: 'Mathematics',

      timeslotId: '12345678qwertyuio'
    },

    seen: false,
    visited: false,
    complete: false,
  },

  from:                 'MAT Interviews <mat-interviews@uci.edu>',
  subject:              '[UCI SoE Dev] MAT Interviews',

  // required in email templates
  frontend:          'https://mat.uci.edu:3000',
  backend:           'http://localhost:4000',
  readReceiptDomain: 'https://api.mat-interviews.educ.aws.uci.edu',

  inviteKey: 'ABC123',
  signedInviteKey: '__FAKE_SIGNED_INVITE_TOKEN__',


};

export default exampleContext;

import CssBaseline                 from '@mui/material/CssBaseline';
import { SnackbarProvider }        from 'notistack';
import { CookiesProvider }         from 'react-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import Apollo                      from './Apollo';
import { AuthProvider }            from './auth';
import DateFns                     from './date-fns';
import ThemeProvider               from './theme';


const SECOND = 1000;

function AppContexts ({ children }) {
  return (
    <ThemeProvider>
      <DateFns>
        <CookiesProvider>
          <SnackbarProvider autoHideDuration={7.5 * SECOND}>

            <CssBaseline />
            <Router>
              <AuthProvider>
                <Apollo>

                  {children}

                </Apollo>
              </AuthProvider>
            </Router>

          </SnackbarProvider>
        </CookiesProvider>
      </DateFns>
    </ThemeProvider>
  );
}

export default AppContexts;

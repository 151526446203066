import { Stack, TextField } from '@mui/material';
import Button               from '@mui/material/Button';
import { useState }         from 'react';

export function DisplayAsText ({ text: _text, onChange: _onChange }) {
  const [ text, setText ] = useState(_text);
  const hasChanged        = text !== _text;

  return (
    <>
      <Stack spacing={2} sx={{ py: 2 }} direction="row">
        <Button variant="contained" disabled={!hasChanged}>Save Changes</Button>
      </Stack>

      <TextField
        multiline
        fullWidth
        value={text}
        onChange={e => setText(e.target.value)}
      />
      <Stack spacing={2} sx={{ py: 2 }} direction="row">
        <Button variant="contained" disabled={!hasChanged}>Save Changes</Button>
      </Stack>
    </>
  );
}

import styled from '@emotion/styled';
import Box    from '@mui/material/Box';

export const InputListContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  borderWidth:  '1px',
  borderStyle:  'solid',
  borderColor:  theme.palette.grey['400'],
  margin:       theme.spacing(2),
  position:     'relative',

  '& .MuiInputLabel-root': {
    position: 'absolute',
    // display:         'inline-block',
    left:            0, top: 0,
    transform:       'translate(14px, -9px) scale(0.75)',
    zIndex:          1,
    pointerEvents:   'auto',
    backgroundColor: theme.palette.common.white,
    padding:         theme.spacing(0, 1),
    marginLeft:      theme.spacing(-1)

  }
}));


export const FormRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin:  theme.spacing(2),

  '& &':              {
    marginLeft:  0,
    marginRight: 0,
  },
  '& &::first-child': {
    marginTop: 0,
  },
  '& &::last-child':  {
    marginBottom: 0,
  }
}));

import { Box, Typography } from '@mui/material';
import Grid                from '@mui/material/Grid';
import Paper               from '@mui/material/Paper';
import { Backpack }        from 'react-kawaii';
import { useSettings }     from '../data/setting';
import Markdown            from '../lib/Markdown';
// import DescriptionText from './components/_DescriptionText';
import StudentForm         from './components/StudentForm';



export default function HasInvite ({ invite, student, onSubmit }) {

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item>
        {/*<Paper sx={{ p: 2 }}>*/}

        {/* Replace with Markdown text */}
        <DescriptionText />

        {/*</Paper>*/}
      </Grid>
      <Grid item>
        <StudentForm student={student} onSubmit={onSubmit} />
      </Grid>
    </Grid>
  );
}


function DescriptionText () {
  const { loading, welcomeIntro } = useSettings([ 'welcome-intro' ])
  console.log({ welcomeIntro })

  if (loading) {
    return (
      <Box textAlign="center">
        <Backpack size={150} mood="excited" color="#CB997E" />
        <Typography variant="body1">
          Loading Welcome Message
        </Typography>
      </Box>
    );

  }

  return (
    <Paper sx={{ p: 3 }}>
      <Markdown>{welcomeIntro.value}</Markdown>
    </Paper>
  )
}

import { useQuery }                                            from '@apollo/client';
import { Box, Collapse, LinearProgress, styled }               from '@mui/material';
import Button                                                  from '@mui/material/Button';
import Divider                                                 from '@mui/material/Divider';
import formatDate                                              from '../utils/formatDate';
import { useSnackbar }                                         from 'notistack';
import { useNavigate, useParams }                              from 'react-router-dom';
import { graphql }                                             from '../data/client';
import { useSettings }                                         from '../data/setting';
import { GQL_MUTATION_UPDATE_STUDENTS, GQL_QUERY_GET_STUDENT } from '../data/student';
import { GQL_QUERY_GET_ALL_TIMESLOTS }                         from '../data/timeslot';
import { Select, TextField, useForm }                          from '../lib/Form';

const FormRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))



export default function NewStudent () {
  const { id } = useParams();

  const {
          getField,
          getValue,
          handleSubmit,
          hasErrors,
          isValid,
          setValues
        } = useForm({
    defaultValues: {
      id,
      firstName: '',
      lastName:  '',
      email:     '',
      phone:     '',

      bilingual:     false,
      program:       '',
      subject:       '',
      uciStudentNum: '',
      // timeslot: Timeslot
      // timeslotId: ID

      timeslotId: '',
    },
    rules:         {
      id:            { required: true },
      uciStudentNum: { required: true },
      firstName:     { required: true, trim: false },
      lastName:      { required: true, trim: false },
      email:         { required: true, isEmail: true },
      phone:         { required: true, isPhone: true, trim: false },
      program:       { required: true },
      subject:       { required: (vals) => vals.program === 'Single Subject' },
      timeslotId:    {},
    },
  });

  const {
          loading,
          error,
          data: studentData,
        }        = useQuery(GQL_QUERY_GET_STUDENT, {
    variables:   { id },
    onCompleted: (data) => {
      const {
              timeslot,
              invites,
              emails,
              __typename,
              ...student
            } = data.getStudent;

      console.log({ timeslot, student })
      setValues({ ...student })
    }
  });
  const {
          loading: timeslotLoading,
          error:   timeslotError,
          data:    timeslotData
        }        = useQuery(GQL_QUERY_GET_ALL_TIMESLOTS);
  const {
          loading: loadingSettings,
          error:   errorSettings,
          programs,
          subjects
        }        = useSettings([
    'programs',
    'subjects'
  ]);
  const {
          enqueueSnackbar,
          closeSnackbar
        }        = useSnackbar();
  const navigate = useNavigate();



  const onSubmit = (values) => {

    const student = {
      id:            values.id,
      uciStudentNum: values.uciStudentNum,
      firstName:     values.firstName,
      lastName:      values.lastName,
      email:         values.email,
      phone:         values.phone,
      ...(values.program ? { program: values.program } : {}),
      ...(values.subject ? { subject: values.subject } : {}),
      ...(values.timeslotId ? { timeslotId: values.timeslotId } : { timeslotId: null }),
    }

    const snack = enqueueSnackbar('Saving Student', { variant: 'info' });
    graphql({
      mutation:  GQL_MUTATION_UPDATE_STUDENTS,
      variables: { student }
    })
      .then(({ data }) => {
        closeSnackbar(snack);
        enqueueSnackbar('Student Saved', { variant: 'success' })
        navigate(`/students/${data.student.id}`)
      })
      .catch(err => {
        closeSnackbar(snack);
        enqueueSnackbar(err.message, { variant: 'warning' })
        console.error(err.message);
      })
    ;
  };

  const timeslots = timeslotData ? timeslotData.listTimeslots.items : [];

  // console.log('timeslots', timeslots)

  const canSubmit = !hasErrors && isValid;

  return (
    <>
      <FormRow>
        <TextField {...getField('uciStudentNum')} label="Student Number" sx={{ width: '100%' }} />
      </FormRow>
      <FormRow>
        <TextField {...getField('firstName')} label="First Name" sx={{ width: '100%' }} />
      </FormRow>
      <FormRow>
        <TextField {...getField('lastName')} label="Last Name" sx={{ width: '100%' }} />
      </FormRow>
      <FormRow>
        <TextField {...getField('email')} label="Email Address" sx={{ width: '100%' }} />
      </FormRow>
      <FormRow>
        <TextField {...getField('phone')} label="Phone Number" sx={{ width: '100%' }} />
      </FormRow>
      <FormRow>
        <Select
          {...getField('program')}
          sx={{ minWidth: '100%' }}
          selectSx={{ minWidth: '100%' }}
          options={programs ? programs.value.map(({ value }) => ({ value, label: value })) : []}
        />
      </FormRow>

      <Collapse in={getValue('program') === 'Single Subject'}>
        <FormRow>
          <Select
            {...getField('subject')}
            sx={{ width: '100%' }}
            selectSx={{ width: '100%' }}
            options={subjects ? subjects.value.map(({ value }) => ({ value, label: value })) : []}
          />
        </FormRow>
      </Collapse>

      <Divider sx={{ mb: 3 }} />

      <FormRow>
        {loading ? <LinearProgress /> : null}
        <Select
          {...getField('timeslotId')}
          disabled={loading}
          sx={{ width: '100%' }}
          selectSx={{ width: '100%' }}
          options={[
            { value: '', label: '<None>' },
            ...timeslots.map(t => ({
              value: t.id,
              label: formatDate(t.start, 'MM/dd/yyyy HH:mm aaa')
                       + ` -- ${t.seatsAvailable} seats remaining`
            }))
          ]}
        />
      </FormRow>



      <Box textAlign="center">
        <Button
          variant="contained"
          disabled={!canSubmit}
          sx={{ width: { xs: '100%', sm: '75%', md: '50%' } }}
          onClick={handleSubmit(onSubmit)}
        >
          Save Student
        </Button>
      </Box>
    </>
  );
}

const _GQL_ENDPOINTS = {
  dev:        'http://localhost:4000/graphql',
  production: 'https://api.mat-interviews.educ.aws.uci.edu/graphql',
};

export const environment = process.env.REACT_APP_STAGE || 'dev';
export const apiServer = _GQL_ENDPOINTS[environment];
export const dataEndpoint = apiServer;
export const emailEndpoint = dataEndpoint.replace(/graphql$/i, 'emails-api');

export const timezoneDesc = 'America/Los_Angeles';


export const issuesLink = 'https://github.oit.uci.edu/education/mat-interviews-react/issues';

const _SAML_ENDPOINTS = {
  // dev:        'http://localhost:3000',
  // dev:    'https://test.saml.educ.aws.uci.edu',
  dev: 'https://saml.educ.aws.uci.edu',
  production: 'https://saml.educ.aws.uci.edu',
};

export const samlServer = _SAML_ENDPOINTS[environment]

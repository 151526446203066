import { gql } from '@apollo/client';
// import { client as apollo } from '../../Context/Apollo';

export const GQL_QUERY_CHECK_EMAIL_TOKEN = gql`
  query getEmailToken($email: ID!, $student: ID!) {
    student: checkEmailToken(email: $email, student: $student) {
      id
      urlId
      firstName
      lastName
      email
      phone
      program
      subject
    }
  }
`;
// export const GQL_QUERY_SUBMIT_REGISTRATION = gql`
//   query getEmailToken($email: ID!, $student: ID!) {
//     success: submitReservation(student) {
//       id
//       urlId
//       firstName
//       lastName
//       email
//       phone
//       program
//       subject
//     }
//   }
// `;

import { Route, Routes } from 'react-router-dom';
import Context           from './Context';

import { AuthGateway } from './Context/auth';
import Layout          from './Layout';
import { Nav }         from './Layout/Nav';
import CatchAll        from './other-pages/CatchAll';
import Loading         from './other-pages/Loading';
import Login           from './other-pages/Login';
import Register        from './Register';
import Settings        from './Settings';
import ListSettings    from './Settings/ListSettings';
import ViewSetting     from './Settings/ViewSetting';
import Student         from './Student';
import BulkAddStudents from './Student/BulkAddStudents';
import EditStudent     from './Student/EditStudent';
import ListStudents    from './Student/ListStudents';
import NewStudent      from './Student/NewStudent';
import ViewStudent     from './Student/ViewStudent';
import Timeslot        from './Timeslot';
import NewTimeslot     from './Timeslot/NewTimeslot';
import ViewTimeslot    from './Timeslot/ViewTimeslot';
import ListTimeslots   from './Timeslot/ListTimeslots';

function App () {
  return (
    <Context>
      <AuthGateway loading={<Loading />} login={<Login />}>

        <Layout nav={Nav}>

          <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/login" element={<Login />} />

            <Route path="/students" element={<Student />}>
              <Route path="new" element={<NewStudent />} />
              <Route path="bulk-add" element={<BulkAddStudents />} />
              <Route path=":id" element={<ViewStudent />} />
              <Route path=":id/edit" element={<EditStudent />} />
              <Route index element={<ListStudents />} />
            </Route>

            <Route path="/timeslots" element={<Timeslot />}>
              <Route path="new" element={<NewTimeslot />} />
              <Route path=":id" element={<ViewTimeslot />} />
              <Route index element={<ListTimeslots />} />
            </Route>

            <Route path="/settings" element={<Settings />}>
              <Route path=":key" element={<ViewSetting />} />
              <Route index element={<ListSettings />} />
            </Route>

            <Route path="*" element={<CatchAll />} />
          </Routes>

        </Layout>
      </AuthGateway>
    </Context>
  );
}

export default App;

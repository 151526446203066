import { gql }                      from '@apollo/client';
// import { graphql } from '../client';

export const GQL_QUERY_VALIDATE_INVITE_TOKEN = gql`
  query verifyInviteToken($token: ID!) {
    payload: validateInviteToken(token: $token) { key email }
  }
`;
export const GQL_QUERY_INVITE_FOR_REGISTRATION = gql`
  query verifyInviteToken($key: ID!, $email: String!) {
    invite: inviteForRegistration(key: $key, email: $email) { 
      key
      email
      
      seen
      visited
      complete
            
      student {
        id
        
        uciStudentNum
        email
        lastName
        firstName
        phone
        program
        subject

        timeslotId
        timeslot {
          id
          day
          startTime
          endTime
          start
          end
          duration
        }
        
        updatedAt
        createdAt
      }
    }
  }
`;

export const GQL_QUERY_FUTURE_DAYS = gql`
  query getFutureDays {
    futureDays {
      items {
        day
        date
        timeslots {
          id
          
          start
          end
          
          day
          startTime
          endTime
          
          seats
          seatsTaken
          seatsAvailable
        }
      }
    }
  }
`;



import { gql }                      from '@apollo/client';
// import { graphql } from '../client';

export const GQL_MUTATION_UPDATE_STUDENT_REGISTRATION = gql`
  mutation updateStudentRegistration($email: String!, $key: ID!, $student: UpdateStudentInput!) {
    register: updateStudentRegistration(email: $email, key: $key, student: $student) {
      key
      email

      seen
      visited
      complete

      student {
        id

        uciStudentNum
        email
        lastName
        firstName
        phone
        program
        subject

        timeslotId
        timeslot {
          id
          day
          startTime
          endTime
          start
          end
          duration
        }

        updatedAt
        createdAt
      }
    }
  }
`;

export const GQL_MUTATION_RESEND_CONFIRM_EMAIL = gql`
  mutation resendConfirmEmail ($student: ID!) {
    success: resendConfirmEmail(studentId: $student)
  }
`;

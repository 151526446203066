import styled                    from '@emotion/styled';
import Box                       from '@mui/material/Box';
import Container                 from '@mui/material/Container';
import CssBaseline               from '@mui/material/CssBaseline';
import { useAuth }               from '../Context/auth';
import { LayoutContextProvider } from './context';
import Copyright                 from './Copyright';
import IssueFooter               from './IssueFooter';
import MenuBar                   from './MenuBar';
import Footer                    from './Footer'

export { useLayout, LayoutContext } from './context';

const RootBG = styled(Box)(({ theme }) => ({
  display:         'flex',
  backgroundColor: theme.palette.grey[100],
  minHeight:       '100vh'
}));

export default function Layout ({ nav, lowerNav, children }) {
  const { isLoggedIn } = useAuth();


  return (
    <LayoutContextProvider>
      {/*<RootBG>*/}
      <CssBaseline />
      <MenuBar nav={nav} />
      {/*<NavDrawer nav={nav} lowerNav={lowerNav} />*/}

      <Container maxWidth="md">
        {children}

        <Box py={3} mb={5} display='flex' flexDirection='column' alignItems={'center'}>
          {isLoggedIn ? (
            <IssueFooter />
          ) : null}
          <Footer />
          <Copyright />
        </Box>
      </Container>
      {/*</RootBG>*/}
    </LayoutContextProvider>
  );
}

import { Ghost }  from 'react-kawaii';
import Grid       from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box        from '@mui/material/Box';

export default function CatchAll () {
  return (
    <Box component='section' sx={{m: 3}}>
      <Grid container justifyContent='center' spacing={3}>
        <Grid item>
          <Ghost size={200} mood='sad' />
        </Grid>

        <Grid item sm={6}>
          <Typography variant='h3' gutterBottom>
            Page Not Found
          </Typography>
          <Typography variant='body1' paragraph>
            Unfortunately, this page either doesn't exist or there was an error in directing where you wanted to go.
          </Typography>
          <Typography variant='body1' paragraph>
            Please try again or contact support.
          </Typography>
        </Grid>
      </Grid>

    </Box>
  );
}

import { gql } from '@apollo/client';

export const GQL_QUERY_GET_INVITE_TOKEN = gql`
  query getInviteToken ($invite: ID!) {
    token: getInviteToken(id: $invite)
  }
`;
export const GQL_QUERY_RESEND_INVITE_EMAIL = gql`
  query resendInviteEmail ($invite: ID!) {
    success: resendInvite(id: $invite)
  }
`;
export const GQL_QUERY_RESEND_INVITE_EMAIL_BY_STUDENT = gql`
  query resendInviteEmailByStudent ($student: ID!) {
    success: resendInviteByStudent(id: $student)
  }
`;

export default function stringToObject (string) {
  return string
    .split(/&/g)
    .map(kv => kv.split(/=/))
    .reduce((obj, [k, v]) => {
      obj[k] = v;
      return obj;
    }, {})
    ;
}

import ArrowDropDownIcon                    from '@mui/icons-material/ArrowDropDown';
import Button                               from '@mui/material/Button';
import ButtonGroup                          from '@mui/material/ButtonGroup';
import Divider                              from '@mui/material/Divider';
import IconButton                           from '@mui/material/IconButton';
import Menu                                 from '@mui/material/Menu';
import MenuItem                             from '@mui/material/MenuItem';
import Tooltip                              from '@mui/material/Tooltip';
import Typography                           from '@mui/material/Typography';
import PropTypes                            from 'prop-types';
import { cloneElement, Fragment, useState } from 'react';


// const noop = () => {
// };

export function ActionButton (
  {
    icon,
    label,
    text,
    textBtnVariant = 'outlined',
    forceText = false,
    iconEdge = 'start',
    size = 'medium',
    color = 'inherit',
    onClick,
    divider = false,
    ...props
  }
) {
  if (divider) {
    return <Divider />;
  }

  const common = {
    'aria-label': label || text,
    size,
    color,
    onClick,
    ...props
  };

  let elem = null;
  if (forceText || (text && !icon)) {
    const btnProps = {
      variant: textBtnVariant
    }
    if (icon) {
      btnProps[`${iconEdge}Icon`] = icon;
    }

    elem = <Button {...common} {...btnProps}>{text}</Button>
  } else if (!icon) {
    throw new Error('No text or icon provided');
  } else {
    elem = (
      <IconButton {...common}>
        {icon}
      </IconButton>
    )
  }

  return (
    <Tooltip title={common['aria-label']}>
      {elem}
    </Tooltip>
  )
}

ActionButton.propTypes = {
  icon:    PropTypes.node,
  label:   PropTypes.string,
  text:    PropTypes.node,
  onClick: PropTypes.func,
  divider: PropTypes.bool
}

export const actionDescPropType = PropTypes.shape({
  icon:    PropTypes.node,
  label:   PropTypes.string,
  text:    PropTypes.node,
  onClick: PropTypes.func,
  divider: PropTypes.bool
})

export function MoreActionMenu (
  {
    actions,
    children,
    component,
    anchorOrigin,
    transformOrigin,
    title,
    ...props
  }
) {
  const [ anchorEl, setAnchorEl ] = useState(null);
  // const { user, logout }          = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (func) => {
    return (e) => {
      setAnchorEl(null);
      if (func) {
        func(e);
      }
    }
  };

  let Elem = component || Fragment;
  if (component && !anchorEl) {
    props.onClick = handleClick;
  }
  const body = cloneElement(children, {
    onClick: handleClick,
    // keepMounted:     true,
    'aria-controls': anchorEl ? 'more-menu' : undefined,
    'aria-expanded': anchorEl ? 'true' : undefined,
    'aria-haspopup': 'true',
  });

  return (
    <Elem {...props}>
      {title ? <Tooltip title={title} children={body} /> : body}
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose()}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {actions.map((a, i) => {
          if (a.divider) {
            return <Divider key={i} />;
          }

          const {
                  icon,
                  label,
                  text,
                  onClick = null,

                  textOnly,

                  // Unused
                  textBtnVariant,
                  forceText,
                  iconEdge,
                  size,
                  color,

                  // Rest
                  ...props
                } = a;

          return (
            <MenuItem key={i} onClick={handleClose(onClick)} {...props}>
              {icon && (
                <IconButton edge="start" aria-label={label || text} color="inherit">
                  {icon}
                </IconButton>
              )}
              <Typography>{text}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </Elem>
  );
}

MoreActionMenu.propTypes = {
  actions:  PropTypes.arrayOf(actionDescPropType).isRequired,
  children: PropTypes.node.isRequired
}

export function MenuButtonGroup (
  {
    text,
    onClick,
    disabled,
    color = 'primary',
    size = 'large',
    disableElevation = false,
    variant = 'contained',
    mainButtonProps = {},
    menuButtonProps = {},
    preventDisableColorChange = false,
    sx = {},
    anchorOrigin = {},
    transformOrigin = {},
    options = [],
  }
) {
  // const theme = useTheme();

  let { sx: sxB1 = {}, ..._mainBtnProps } = mainButtonProps;
  let { sx: sxB2 = {}, ..._menuBtnProps } = menuButtonProps;

  sxB1 = {
    whiteSpace: 'nowrap',
    ...sxB1
  }

  // let sxB1 = mainButtonProps.sx || {};
  // let sxB2 = menuButtonProps.sx || {}
  if (size === 'xsmall') {
    size = 'small';
    sx   = {
      ...sx,
      mt: 0.5,
    }
    sxB1 = {
      ...sxB1,
      py:         0.0,
      px:         0.75,
      fontSize:   11,
      lineHeight: 1
    }
    sxB2 = {
      ...sxB2,
      py:         0.0,
      px:         0.5,
      fontSize:   11,
      minWidth:   '0 !important',
      lineHeight: 1
    }
  }

  if (preventDisableColorChange) {
    sxB1 = {
      ...sxB1,
      '&.Mui-disabled': {
        bgcolor: `${color}.main`,
        color:   `${color}.contrastText`
      }
    }
  }

  return (
    <>
      <ButtonGroup color={color} variant={variant} size={size} aria-label="split button"
                   disableElevation={disableElevation} sx={sx}>
        <Button
          disabled={disabled} onClick={onClick} sx={sxB1} {..._mainBtnProps}>{text}</Button>

        {options.length && (
          <MoreActionMenu
            actions={options}
            component={Button}
            size={size}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            sx={sxB2}
            {..._menuBtnProps}
          >
            <ArrowDropDownIcon />
          </MoreActionMenu>
        )}
      </ButtonGroup>
    </>
  );

}

MenuButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      text:     PropTypes.string.isRequired,
      onClick:  PropTypes.func.isRequired,
      disabled: PropTypes.bool
    }),
    PropTypes.shape({
      divider: PropTypes.bool,
    })
  ])).isRequired,
  text:    PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
}

import { useQuery }               from '@apollo/client';
import camelcase                  from 'camelcase';
import { GQL_QUERY_GET_SETTINGS } from './queries';

export * from './general';
export * from './unauthorized';

export * from './queries';
export * from './mutations';


function parseSetting (value, type) {
  switch (type) {
    case 'json':
    case 'array<string>':
    case 'array<number>':
    case 'array<int>':
    case 'array<float>':
      return JSON.parse(value);
    case 'int':
      return parseInt(value);
    case 'float':
      return parseFloat(value);
    case 'string':
    case 'markdown':
      return value;
    case 'boolean':
      return !!value;
    default:
      throw new Error(`No known type provided to parser. Type provided: ${type}`);
  }
}

function stringifySetting (value, type) {
  switch (type) {
    case 'json':
    case 'array<string>':
    case 'array<number>':
    case 'array<int>':
    case 'array<float>':
      return JSON.stringify(value);
    case 'int':
    case 'float':
      return value.toString();
    case 'string':
    case 'markdown':
      return value;
    case 'boolean':
      return value ? '1' : '0';
    default:
      throw new Error(`No known type provided to stringifier. Type provided: ${type}`);
  }
}

export function useSettings (keys = []) {
  const { loading, error, data } = useQuery(GQL_QUERY_GET_SETTINGS, { variables: { keys } });

  const settings = data ? data.getSettings.reduce((obj, s) => {
    const k = camelcase(s.key);
    obj[k] = { ...s };
    obj[k].value = parseSetting(s.value, s.type);
    return obj;
  }, {}) : {};

  return {
    loading,
    error,
    ...settings,
  };
}

import Box          from '@mui/material/Box';
import Typography   from '@mui/material/Typography';
import { Backpack } from 'react-kawaii';

export function LoadingBackpack () {
  return (
    <Box display="flex" justifyContent={'center'} alignItems={'center'}>
      <Backpack size={100} />
      <Typography variant={'h5'} sx={{ m: 2 }}>
        Loading Setting Data
      </Typography>
    </Box>
  );
}

import { gql }              from '@apollo/client';
// import { client as apollo } from '../../Context/Apollo';

export const GQL_MUTATION_UPDATE_STUDENTS = gql`
  mutation updateStudents($student: UpdateStudentInput!) {
    student: updateStudent(input: $student) {
      id
    }
  }
`;
export const GQL_MUTATION_GET_RANDOM_TEST_PEOPLE = gql`
  mutation getRandomPeople ($count: Int, $email: String) {
    csv: getRandomTestPeople (count: $count, email: $email)
  }
`;
export const GQL_MUTATION_CREATE_STUDENT = gql`
  mutation createStudent ($student: CreateStudentInput!) {
    student: createStudent (input: $student) {
      id
      uciStudentNum
      lastName
      firstName
    }
  }
`;

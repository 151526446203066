import { ListItem, ListItemText } from '@mui/material';
import List                       from '@mui/material/List';
import Paper                      from '@mui/material/Paper';
import Typography                 from '@mui/material/Typography';

export default function Completed ({ invite, student, timeslot }) {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant={'h5'} paragraph>
        Thank You for Registering
      </Typography>
      <Typography paragraph>
        This invitation has been completed. You should receive an email confirming your registration.
      </Typography>
      <Typography paragraph>
        If you need to change your registration, did not register, or think you've reached this page in error, please
        email <a href="mailto:mat-interviews@uci.edu">Mat-Interviews@uci.edu</a>.
      </Typography>

      <Typography variant={'h5'} sx={{ pt: 2 }}>
        Registration Details
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary={timeslot.day} secondary="Day" />
        </ListItem>
        <ListItem>
          <ListItemText primary={`${timeslot.startTime} Pacific`} secondary="Start Time" />
        </ListItem>
        <ListItem>
          <ListItemText primary={`${timeslot.endTime} Pacific`} secondary="End Time" />
        </ListItem>
        <ListItem>
          <ListItemText primary={timeslot.duration} secondary="Duration" />
        </ListItem>
      </List>
    </Paper>
  );
}

import { Box, Typography } from '@mui/material';
import { Folder }          from 'react-kawaii';

export function NoStudent () {
  return (
    <Box textAlign="center">
      <Folder size={150} mood="excited" color="#CB997E" />
      <Typography variant="body1">
        No Student Found
      </Typography>
    </Box>
  );
}

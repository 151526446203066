import { useQuery }       from '@apollo/client';
import AddCircleIcon      from '@mui/icons-material/AddCircle';
import ContentCopyIcon    from '@mui/icons-material/ContentCopy';
import DeleteIcon         from '@mui/icons-material/Delete';
import DoneIcon           from '@mui/icons-material/Done';
import EditIcon           from '@mui/icons-material/Edit';
import LinkIcon           from '@mui/icons-material/Link';
import MoreVertIcon       from '@mui/icons-material/MoreVert';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RotateLeftIcon     from '@mui/icons-material/RotateLeft';
import SendIcon           from '@mui/icons-material/Send';
import VisibilityIcon     from '@mui/icons-material/Visibility';
import VisibilityOffIcon  from '@mui/icons-material/VisibilityOff';
import WebIcon            from '@mui/icons-material/Web';
import WebAssetOffIcon    from '@mui/icons-material/WebAssetOff';
import {
  Card,
  CardHeader,
  DialogContentText,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Tooltip
}                         from '@mui/material';
import Button             from '@mui/material/Button';
import IconButton         from '@mui/material/IconButton';
import Link               from '@mui/material/Link';
import List               from '@mui/material/List';
import Typography         from '@mui/material/Typography';
import formatDate         from '../utils/formatDate';
import {
  useMemo
}                                            from 'react';
import {
  useNavigate,
  useParams
}                                            from 'react-router-dom';
import {
  graphql
}                                            from '../data/client';
import {
  GQL_MUTATION_CREATE_INVITE
}                                            from '../data/invite';
import { GQL_MUTATION_RESEND_CONFIRM_EMAIL } from '../data/register/mutations';
import {
  deleteStudentMutation,
  GQL_QUERY_GET_INVITE_TOKEN,
  GQL_QUERY_GET_STUDENT,
  GQL_QUERY_RESEND_INVITE_EMAIL
}                                            from '../data/student';
// import { sendInvite }                                   from '../lib/emails';
import {
  useDialogCtrl
}                                            from '../lib/Form';
import {
  MoreActionMenu
}                         from '../lib/menuElements';
import useSubmitSnack     from '../lib/snacks';
import {
  ErrorBlurb
}                         from '../other-pages/Error';
import {
  getDomain
}                         from '../other-pages/Login';
import {
  loadableProxy
}                         from '../Timeslot/ViewTimeslot';
import {
  NoStudent
}                         from './components/NoStudent';

export default function ViewStudent () {
  const { id }                            = useParams();
  const { loading, error, data, refetch } = useQuery(GQL_QUERY_GET_STUDENT, {
    variables: { id }
  });
  const confirmDialog                     = useDialogCtrl({});
  const snacks                            = useSubmitSnack();
  const navigate                          = useNavigate();

  // const student = loading ? {} : data.getStudent;
  const student = useMemo(() => loadableProxy(data && data.getStudent, loading), [ data, loading ]);

  if (error) {
    return <ErrorBlurb err={error} />
  }
  if (!loading && !(data && data.getStudent)) {
    return <NoStudent />;
  }



  const handleDelete = () => {
    snacks.start('Removing Student');
    deleteStudentMutation({ student: id })
      .then(() => {
        snacks.success('Removed Student');
        navigate('/students');
      })
      .catch(snacks.catchError)
    ;
  }

  const handleDeleteConfirm = () => {
    const onDelete = () => {
      handleDelete();
      confirmDialog.closeDialog();
    }

    confirmDialog.openDialog({
      title:    'Are you sure?',
      actions:  [
        [
          <Button key={0} onClick={onDelete} variant="outlined">
            Remove Student
          </Button>,
          <Button key={1} autoFocus onClick={confirmDialog.closeDialog} variant="contained">
            Cancel
          </Button>
        ]
      ],
      children: (
                  <DialogContentText>
                    Please confirm you wish to remove {student.firstName} {student.lastName} {'<'}{student.email}>
                  </DialogContentText>
                )
    })
  }

  const timeslot = student.get((loading, { timeslot: ts }) => {
    if (loading) {
      return <Skeleton />;
    }

    if (ts) {
      return (
        <Typography>
          {ts.day} {ts.startTime}-{ts.endTime}
          <IconButton component={Link} href={`/timeslots/${ts.id}`} color="primary"><LinkIcon /></IconButton>
        </Typography>
      )
    }

    return <Typography color={'gray'}>None Selected</Typography>
  })

  const sendInvite = () => {
    snacks.start('Creating Invitation');
    graphql({
      mutation:  GQL_MUTATION_CREATE_INVITE,
      variables: { student: id, sendEmail: true }
    })
      .then(() => {
        snacks.success('Invite Created');
        return refetch({ id })
      })
      .catch(snacks.catchError)
  }
  const resendConfirmEmail = () => {
    snacks.start('Resending Confirmation Email');
    graphql({
      mutation:  GQL_MUTATION_RESEND_CONFIRM_EMAIL,
      variables: { student: id }
    })
      .then(() => {
        snacks.success('Confirmation Email Resent');
        return refetch({ id })
      })
      .catch(snacks.catchError)
  }



  return (
    <>
      <Card variant={'outlined'} sx={{ mb: 2 }}>
        <CardHeader title={'Details'} action={
          <MoreActionMenu
            actions={[
              { text: 'Edit Student', icon: <EditIcon />, component: Link, href: `/students/${student.id}/edit` },
              { divider: true },
              { text: 'Delete', icon: <DeleteIcon />, onClick: handleDeleteConfirm },
            ]}
            anchorOrigin={{
              vertical:   'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical:   'top',
              horizontal: 'right',
            }}
          >
            <IconButton color="inherit">
              <MoreVertIcon />
            </IconButton>
          </MoreActionMenu>
        } />

        <List sx={{ flexGrow: 1 }}>
          <ListItem>
            <ListItemText primary={student.uciStudentNum} secondary="UCI Student Number" />
          </ListItem>
          <ListItem>
            <ListItemText primary={student.firstName} secondary="First Name" />
          </ListItem>
          <ListItem>
            <ListItemText primary={student.lastName} secondary="Last Name" />
          </ListItem>
          <ListItem>
            <ListItemText primary={student.email} secondary="Email Address" />
          </ListItem>
          <ListItem>
            <ListItemText primary={student.phone} secondary="Phone Number" />
          </ListItem>
          <ListItem>
            <ListItemText primary={student.program} secondary="Program" />
          </ListItem>
          {student.subject ? (
            <ListItem>
              <ListItemText primary={student.subject} secondary="Subject" />
            </ListItem>
          ) : null}
          <Divider />
          <ListItem>
            <ListItemText primary={timeslot} secondary="Timeslot" />
            <ListItemSecondaryAction>
              <Tooltip title={'Resend Confirmation Email'}>
                <IconButton onClick={resendConfirmEmail}>
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Edit'}>
                <IconButton component={Link} href={`/students/${id}/edit`}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>

      </Card>

      <Card variant={'outlined'} sx={{ mb: 2 }}>
        <CardHeader title="Invitations" action={
          <MoreActionMenu
            actions={[
              { text: 'Send New Invitation', icon: <AddCircleIcon />, onClick: sendInvite },
            ]}
            anchorOrigin={{
              vertical:   'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical:   'top',
              horizontal: 'right',
            }}
          >
            <IconButton color="inherit">
              <MoreVertIcon />
            </IconButton>
          </MoreActionMenu>
        } />

        <List>

          {student.invites && student.invites.length ? student.invites.map(i => (
            <InviteListItem key={i.key} invite={i} />
          )) : (
             <ListItemButton onClick={sendInvite}>
               <ListItemIcon>
                 <AddCircleIcon />
               </ListItemIcon>
               <ListItemText primary="Send New Invitation" />
             </ListItemButton>
           )}

        </List>

      </Card>

      <Card variant={'outlined'} sx={{ mb: 2 }}>
        <CardHeader title="Emails" />

        <List>
          {student.emails && student.emails.length ? student.emails.map(e => (
            <EmailListItem key={e.id} email={e} />
          )) : (
             <ListItem>
               <ListItemText primary="No Emails Sent" />
             </ListItem>
           )}


        </List>

      </Card>

      {confirmDialog.open && confirmDialog.getDialog()}

    </>
  );
}


function NoEmailPlaceholder () {
  return <TableRow>
    <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
      No emails yet
    </TableCell>
  </TableRow>
}

function InviteListItem ({ invite }) {
  const clipboard = window.navigator.clipboard;
  const snacks    = useSubmitSnack();
  const onAction  = (action, data) => {
    switch (action) {
      case 'resend-invite':
        return graphql({
          query:     GQL_QUERY_RESEND_INVITE_EMAIL,
          variables: { invite: invite.id }
        })
          .then(() => snacks.success('Invitation re-sent'))
          .catch(snacks.catchError('Email failed to send.'));
      case 'copy-invite':
        return graphql({
          query:     GQL_QUERY_GET_INVITE_TOKEN,
          variables: { invite: invite.id }
        })
          .then(({ data }) => {
            const token = data.token;
            clipboard.writeText(`${getDomain()}#k=${token}`);
            snacks.success('URL Copied to Clipboard');
          })
          .catch(snacks.catchError('Problem copying data.'));
    }

  }

  return (
    <ListItem alignItems={'center'}>
      <ListItemText primary={invite.key} secondary={`Created: ${formatDate(invite.createdAt, 'MM/dd/yyyy')}`} />
      <InviteStatusDisplay seen={invite.seen} visited={invite.visited} complete={invite.complete} onAction={onAction} />
    </ListItem>
  );
}

function InviteStatusDisplay ({ seen, visited, complete, onAction }) {

  const copyInvitationLink = () => onAction('copy-invite');
  const resendInviteEmail  = () => onAction('resend-invite');


  return (
    <Stack direction="row" sx={{
      display: 'flex',

      alignItems:            'center',
      width:                 'fit-content',
      border:                (theme) => `1px solid ${theme.palette.divider}`,
      borderRadius:          1,
      bgcolor:               'background.paper',
      color:                 'text.secondary',
      '& > svg, & > button': {
        mx: 1,
      },
      '& > hr':              {
        mx: 0.5,
      },
    }}>
      <TooltippedIcon
        title={seen ? 'Email has been seen' : 'Email probably unseen'}
        icon={seen ? <VisibilityIcon color={'success'} /> : <VisibilityOffIcon color={'disabled'} />}
      />
      <TooltippedIcon
        title={visited ? 'Visited the website' : 'Not visited the website'}
        icon={visited ? <WebIcon color={'success'} /> : <WebAssetOffIcon color={'disabled'} />}
      />
      <TooltippedIcon
        title={complete ? 'Registration complete' : 'Registration pending'}
        icon={complete ? <DoneIcon color={'success'} /> : <PendingActionsIcon color={'disabled'} />}
      />
      <Divider orientation="vertical" variant="middle" flexItem />
      <MoreActionMenu
        actions={[
          { text: 'Resend Invite Email', icon: <SendIcon />, onClick: resendInviteEmail },
          { text: 'Copy Invite Link to Clipboard', icon: <ContentCopyIcon />, onClick: copyInvitationLink },
        ]}
        anchorOrigin={{
          vertical:   'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical:   'top',
          horizontal: 'right',
        }}
      >
        <IconButton color="inherit">
          <MoreVertIcon />
        </IconButton>
      </MoreActionMenu>
    </Stack>
  );
}

function TooltippedIcon ({ title, icon, buttonProps, ...tooltipProps }) {
  return (
    <Tooltip title={title} {...tooltipProps}>
      {buttonProps ? (
        <IconButton {...buttonProps}>
          {icon}
        </IconButton>
      ) : icon}
    </Tooltip>
  );
}


const emailTypeMap = {
  'invite-email': 'Invitation to Register',
  'confirm-email': 'Confirmation Email'
}

function EmailListItem ({ email }) {

  console.log(email)

  return (
    <ListItem alignItems={'center'}>
      <ListItemText primary={emailTypeMap[email.type] || 'Unknown Type'}
                    secondary={`Created: ${formatDate(email.createdAt, 'MM/dd/yyyy HH:mm:ss')}`} />
    </ListItem>
  );
}

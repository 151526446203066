import { useQuery }                 from '@apollo/client';
import { GQL_QUERY_REDIRECT_TOKEN } from '../../data/auth';
import { graphql }                  from '../../data/client';
import { LOGIN_HREF }               from './index';


export function useRedirectToken () {
  const { data, loading, error } = useQuery(GQL_QUERY_REDIRECT_TOKEN, {
    variables: { url: window.location.origin }
  });

  return { redirectToken: data && data.token, loading, error };
}

export async function handleLoginClick (redirectUri = '') {
  const location = window.location;

  console.log('redirectURI', redirectUri)
  try {
    const { data } = await graphql({
      query:     GQL_QUERY_REDIRECT_TOKEN,
      variables: { url: window.location.origin + redirectUri }
    })
    console.log(`${LOGIN_HREF}?return-url=${data && data.token}`)
    location.href = `${LOGIN_HREF}?return-url=${data && data.token}`;

  } catch (err) {
    console.error('There has been a problem loading redirect token for login', err)
    // alert('There has been an error. You may not be connected to the internet. Please refresh this page and try again. If you continue to get this error, please contact the administrator.');
  }
}

import AccessTimeIcon          from '@mui/icons-material/AccessTime';
import CategoryIcon            from '@mui/icons-material/Category';
import CheckCircleOutlineIcon  from '@mui/icons-material/CheckCircleOutline';
import FactCheckIcon           from '@mui/icons-material/FactCheck';
import PersonIcon              from '@mui/icons-material/Person';
import SearchIcon              from '@mui/icons-material/Search';
import ShortTextIcon           from '@mui/icons-material/ShortText';
import UpdateIcon              from '@mui/icons-material/Update';
import { Drawer, TextField }   from '@mui/material';
import Box                     from '@mui/material/Box';
import Button                  from '@mui/material/Button';
import Divider                 from '@mui/material/Divider';
import { isEqual }             from 'date-fns';
import startOfMonth            from 'date-fns/startOfMonth';
import startOfToday            from 'date-fns/startOfToday';
import startOfWeek             from 'date-fns/startOfWeek';
import subMonths               from 'date-fns/subMonths';
import subWeeks                from 'date-fns/subWeeks';
import { useSettings }         from '../../data/setting';
import { FormRow, SelectList } from '../../lib/Form';


const removeKey = (key) => {
  return (f) => {
    return Object.getOwnPropertyNames(f)
      .filter(k => k !== key)
      .reduce((obj, k) => {
        obj[k] = f[k];
        return obj;
      }, {})
  }
}

const arrayIncludes = (arr, val) => {
  if (!arr) {
    return false;
  }
  if (typeof val === 'function') {
    return arr.filter(val).length > 0;
  }
  return arr.includes(val);
}


export default function AssetsFilterDrawer (
  {
    open = false,
    onClose,
    filter = {},
    setFilter
  }
) {
  const { loading, programs, subjects } = useSettings([ 'programs', 'subjects' ]);

  const setSingleOption = (key) => {
    const isDate = [ 'date', 'createdAt', 'updatedAt' ].includes(key);

    return (option) => {
      if (
        isDate
        ? isEqual(filter[key], option.value)
        : filter[key] === option.value
      ) {
        setFilter(removeKey(key))
      } else {
        setFilter(f => ({ ...f, [key]: option.value }));
      }
    }
  }

  const setMultiOption = (key) => {
    const isDate = [ 'date', 'createdAt', 'updatedAt' ].includes(key);

    return (option) => {

      if (!filter[key]) {
        setFilter(f => ({ ...f, [key]: [ option.value ] }));
      } else {
        const __searchFunc = (index, d, i) => index > -1 || !isEqual(d, option.value) ? index : i;
        const searchIndex  = isDate
                             ? filter[key].reduce(__searchFunc, -1)
                             : filter[key].indexOf(option.value)
        ;

        if (searchIndex > -1) {
          const tmp = filter[key].filter((_, i) => i !== searchIndex);
          setFilter(f => ({ ...f, [key]: tmp }));
        } else {
          setFilter(f => ({ ...f, [key]: [ ...f[key], option.value ] }));
        }
      }

    }
  }

  const setText = (key) => {
    return (e) => {
      const value = e.target.value;

      if (value === '') {
        // remove key
        setFilter(({ [key]: _, ...f }) => ({ ...f }))
      } else {
        //set key
        setFilter(f => ({ ...f, [key]: e.target.value }))
      }
    }
  }

  const keyReducer = (e) => {
    // console.log(e)
    if (e.type === 'keydown') {
      if ([ 'Enter', 'Escape' ].includes(e.key)) {
        onClose();
      }
    }
  }


  /*
    in: [ID]

    uciStudentNum: String
    firstName: String
    lastName: String
    email: String
    phone: String

    program: String
    program_in: [String]
    subject: String
    subject_in: [String]

    hasTimeslot: Boolean

    text: String
    createdAt: Date
    updatedAt: Date
   */


  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      onKeyDown={keyReducer}
      role="presentation"
      sx={{ zIndex: 1500 }}
    >
      <Box maxWidth="320px" width="100vw">

        <FormRow sx={{ mt: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', width: 1 }}>
            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label="Search"
              variant="standard"
              value={filter.text || ''}
              autoFocus
              onChange={setText('text')}
              fullWidth
            />
          </Box>
        </FormRow>

        <SelectList
          icon={<AccessTimeIcon />}
          label="Created"
          onChange={setSingleOption('createdAt')}
          options={[
            { label: 'Today', value: startOfToday() },
            { label: 'This Week', value: startOfWeek(new Date()) },
            { label: 'Last Week', value: subWeeks(startOfWeek(new Date()), 1) },
            { label: 'This Month', value: startOfMonth(new Date()) },
            { label: 'Last Month', value: subMonths(startOfMonth(new Date()), 1) },
          ].map(o => {
            o.selected = isEqual(filter.createdAt, o.value);
            return o;
          })}
        />

        <SelectList
          icon={<UpdateIcon />}
          label="Updated"
          onChange={setSingleOption('updatedAt')}
          options={[
            { label: 'Today', value: startOfToday() },
            { label: 'This Week', value: startOfWeek(new Date()) },
            { label: 'Last Week', value: subWeeks(startOfWeek(new Date()), 1) },
            { label: 'This Month', value: startOfMonth(new Date()) },
            { label: 'Last Month', value: subMonths(startOfMonth(new Date()), 1) },
          ].map(o => {
            o.selected = isEqual(filter.updatedAt, o.value);
            return o;
          })}
        />

        <SelectList
          icon={<CategoryIcon />}
          label="Programs"
          onChange={setMultiOption('program_in')}
          options={
            loading
            ? []
            : programs.value.map(({ value }) => ({
              label:    value,
              value:    value,
              selected: arrayIncludes(filter.program_in, value)
            }))
          }
        />

        <SelectList
          icon={<FactCheckIcon />}
          label="Subjects"
          onChange={setMultiOption('subject_in')}
          options={
            loading
            ? []
            : subjects.value.map(({ value }) => ({
              label:    value,
              value:    value,
              selected: arrayIncludes(filter.subject_in, value)
            }))
          }
        />

        <SelectList
          icon={<CheckCircleOutlineIcon />}
          label="Has Timeslot"
          onChange={setSingleOption('hasTimeslot')}
          options={[
            { label: 'Yes', value: true, selected: filter.hasTimeslot === true },
            { label: 'No', value: false, selected: filter.hasTimeslot === false },
          ]}
        />

        <SelectList
          icon={<CheckCircleOutlineIcon />}
          label="Invite Seen"
          onChange={setSingleOption('inviteSeen')}
          options={[
            { label: 'Yes', value: true, selected: filter.inviteSeen === true },
            { label: 'No', value: false, selected: filter.inviteSeen === false },
          ]}
        />

        <SelectList
          icon={<CheckCircleOutlineIcon />}
          label="Invite Visited"
          onChange={setSingleOption('inviteVisited')}
          options={[
            { label: 'Yes', value: true, selected: filter.inviteVisited === true },
            { label: 'No', value: false, selected: filter.inviteVisited === false },
          ]}
        />

        <SelectList
          icon={<CheckCircleOutlineIcon />}
          label="Invite Complete"
          onChange={setSingleOption('inviteComplete')}
          options={[
            { label: 'Yes', value: true, selected: filter.inviteComplete === true },
            { label: 'No', value: false, selected: filter.inviteComplete === false },
          ]}
        />

        <FormRow sx={{ mt: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', width: 1 }}>
            <ShortTextIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label="Make or Model"
              variant="standard"
              value={filter.makeModel || ''}
              onChange={setText('makeModel')}
              fullWidth
            />
          </Box>
        </FormRow>

        <FormRow sx={{ mt: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', width: 1 }}>
            <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              label="Owner or User/Group"
              variant="standard"
              value={filter.owner || ''}
              onChange={setText('owner')}
              fullWidth
            />
          </Box>
        </FormRow>
        {/*  fact check, flag*/}


      </Box>

      <Divider />

      <Box display="flex" padding={2}>
        <Button onClick={() => setFilter({})}>
          Clear Filters
        </Button>
      </Box>
    </Drawer>
  );
}

import CheckIcon      from '@mui/icons-material/Check';
import CloseIcon      from '@mui/icons-material/Close';
import DownloadIcon   from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
}                                     from '@mui/material';
import Button                         from '@mui/material/Button';
import List                           from '@mui/material/List';
import Typography                     from '@mui/material/Typography';
import Papa                           from 'papaparse';
import {
  useState
}                                     from 'react';
import {
  graphql
}                                     from '../data/client';
import { GQL_MUTATION_CREATE_INVITE } from '../data/invite';
import {
  GQL_MUTATION_CREATE_STUDENT,
  GQL_MUTATION_GET_RANDOM_TEST_PEOPLE
}                                     from '../data/student';
import useSubmitSnack                 from '../lib/snacks';
import downloadCsvToUser              from '../utils/downloadCsvToUser';


// startProcessing () {
//   const data   = this.get('toProcess');
//   const notify = this.get('notify');
//   const store  = this.get('store');
//
//   const report = (() => {
//     let arr = [];
//
//     return (str, newlines = 0) => {
//       arr.push(str);
//       if (newlines) {
//         for (newlines; newlines > 0; newlines--) {
//           arr.push('');
//         }
//       }
//
//       this.set('processingText', arr.join('<br />'));
//     }
//   })();
//
//   if (!data.length) {
//     notify.alert('Cannot process data; no data available');
//   }
//
//   this.set('processing', true);
//   this.set('processingDone', false);
//   report('Processing ...', 1);
//   report(`Creating ${data.length} students`);
//
//   const stuPromises = data.map(d => {
//     let stu = store.createRecord('student', d);
//
//     return store
//       .query('student', {
//         filter: {email: stu.get('email')}
//       })
//       .then(exists => {
//         // debugger
//         if (exists && exists.meta.count > 0) {
//           report(`Cannot create record for student, ${stu.get('lastFirstName')}, `
//             + `email already exists under student ${exists.get('firstObject').get('lastFirstName')}`);
//
//           stu.unloadRecord();
//
//           return null;
//         } else {
//
//           report(`Creating Record: ${stu.get('lastFirstName')}`);
//
//           return stu.save()
//             .then(stu => {
//               report(`${stu.get('lastFirstName')} Saved Successfully`);
//               return stu;
//             });
//         }
//       })
//       ;
//   });
//
//   RSVP.all(stuPromises)
//     .then(students => {
//       const saved = students.filter(s => !!s);
//       report(`${saved.length} saved successfully`);
//       this.set('students', saved);
//       this.set('processingDone', true);
//     })
//   ;
//
// }


export default function BulkAddStudents () {
  const [ toProcess, setToProcess ]     = useState([]);
  const [ cantProcess, setCantProcess ] = useState([]);
  const snack                           = useSubmitSnack();


  const clearInput = () => {
    setToProcess([]);
    setCantProcess([]);
  };
  const parseFile  = function ([ file ], e) {

    // Check for files or clear
    if (!file) {
      return clearInput();
    }
    // Check if file is type CSV
    if (!file.type || !/\/csv/i.test(file.type)) {
      // communicate error to user
      snack.error(`File input must be of content-type csv. Given file is of type ${file.type}`);
      return clearInput();
    }

    Papa.parse(file, {
      // delimiter: "",	// auto-detect
      // newline: "",	// auto-detect
      // quoteChar: '"',
      header: true,
      // dynamicTyping: false,
      // preview: 0,
      // encoding: "",
      // worker: false,
      // comments: false,
      // step: undefined,
      complete: ({ data, errors, meta }) => {
        if (errors.length) {
          snack.error(`There were errors with the data`);
          return clearInput();
        }

        let toProcessTmp   = [];
        let cantProcessTmp = [];

        data.forEach(stu => {
          if (!stu.first_name || !stu.last_name || !stu.email) {
            cantProcessTmp.push(stu);
          } else {
            let out = {
              uciStudentNum: stu.number,
              firstName:     stu.first_name,
              lastName:      stu.last_name,
              email:         stu.email
            };

            if (stu.phone) {
              out.phone = stu.phone;
            }
            if (stu.program) {
              out.program = stu.program;
            }
            if (stu.subject) {
              out.subject = stu.subject;
            }

            toProcessTmp.push(out);
          }
        });

        // console.log({
        //   toProcessTmp,
        //   cantProcessTmp
        // })

        setToProcess(toProcessTmp);
        setCantProcess(cantProcessTmp);
      },
      // error: undefined,
      // download: false,
      // skipEmptyLines: false,
      // chunk: undefined,
      // fastMode: undefined,
      // beforeFirstChunk: undefined,
      // withCredentials: undefined
    })

  };

  const handleAddStudents = () => {
    if (!toProcess.length) {
      snack.error('Cannot process data; no data available');
      return;
    }
    console.group('Processing Students');

    snack.start('Processing Students');
    console.log(`Creating ${toProcess.length} students`);
    const prgMap = {
      'SS': 'Single Subject',
      'MS': 'Multiple Subject',
      'MSBA': 'Multiple Subject Bilingual Authorization'
    }

    const stuPromises = toProcess.map(s => {
      console.log(`Creating Record: ${s.lastName}, ${s.firstName}`);

      const program = prgMap[prgMap];

      return graphql({
        mutation:  GQL_MUTATION_CREATE_STUDENT,
        variables: {
          student: {
            ...s,
            program
          }
        }
      })
        .then(({ data }) => {
          console.log(`${data.student.lastName}, ${data.student.firstName}: Saved Successfully`);
          return data.student;
        })
        .catch(err => {
          // console.log(s);
          snack.error(`${s.uciStudentNum} already in database: Didn't make ${s.lastName}, ${s.firstName}`)
          if (/duplicate key/.test(err.message)) {
            console.log(`Cannot create record for student: ${s.lastName}, ${s.firstName}, Student number '${s.uciStudentNum}' already exists`);
            return null;
          } else {
            console.error(err);
            throw err;
          }
        })

        ;
    });

    Promise.all(stuPromises)
      .then(students => {
        const saved = students
          .filter(s => !!s)
          .map(s => {
            return graphql({
              mutation:  GQL_MUTATION_CREATE_INVITE,
              variables: {
                student:   s.id,
                sendEmail: true
              }
            })
          })
        ;


        snack.success(`${saved.length} students created successfully`, {
          variant: saved.length ? 'success' : 'info',
        })
        console.log(`${saved.length} students created successfully`);
        return Promise.all(saved);
      })
      .then(invites => {
        snack.success(`${invites.length} invites created and emails sent`, {
          variant: invites.length ? 'success' : 'info',
        })
        console.log(`${invites.length} invites created successfully`);
        console.groupEnd();
      })
      .catch(err => {
        console.error(err.stack)
      })
    ;

  }



  return (
    <>
      <Typography variant="body1" paragraph>
        Upload a CSV File of student data and create multiple students at once.
      </Typography>

      <Accordions />

      <Box mt={5}>

        <form>
          <label htmlFor="bulk-create-upload" className="form-control-label d-block">
            Select CSV File of Students
          </label>
          <label htmlFor="bulk-create-upload">
            <input accept=".csv, text/csv" id="bulk-create-upload" type="file" style={{ display: 'none' }}
                   onChange={(e) => parseFile(e.target.files, e)}
                   onClick={(e) => e.target.value = null} />
            <Button variant="contained" component="span" sx={{ ml: 2 }}>
              *.csv
            </Button>
          </label>
        </form>

      </Box>

      <List sx={{ my: 2 }}>
        <ListItem>
          <ListItemIcon>
            <CheckIcon color="success" />
          </ListItemIcon>
          <ListItemText primary={`Ready to process ${toProcess.length} student(s)`} />
        </ListItem>
        {cantProcess.length ? (
          <ListItem>
            <ListItemIcon>
              <CloseIcon color="warning" />
            </ListItemIcon>
            <ListItemText
              primary={`Unable to process ${cantProcess.length} student(s)`}
              secondary={`Likely due to missing data.`}
            />
          </ListItem>
        ) : null}
      </List>

      <Button disabled={!toProcess.length} variant="contained" onClick={handleAddStudents}>Start Processing</Button>


    </>
  )
}



function Accordions () {
  const snacks              = useSubmitSnack();
  const [ email, setEmail ] = useState();

  const getPeople = (count = 10, email) => {
    snacks.start('getting random people')
    graphql({
      mutation:  GQL_MUTATION_GET_RANDOM_TEST_PEOPLE,
      variables: { count, email }
    })
      .then(({ data }) => {
        console.log(data)
        snacks.success('Success. Initiating Download');
        downloadCsvToUser(data.csv, 'random-people.csv');
      })
      .catch(snacks.catchError('failed to get data'))
  }


  return (
    <>
      <Accordion>

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="xlsx-content"
          id="xlsx-header"
        >
          Microsoft Excel to CSV
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            <li>Click File</li>
            <li>Click "Save as..."</li>
            <li>Change "File Format" dropdown to "Comma Separated Values (.csv)"</li>
            <li>Chose location and file name to save file</li>
            <li>Click "Save"</li>
          </ol>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="gsheets-content"
          id="gsheets-header"
        >
          Google Sheets to CSV
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            <li>Click File</li>
            <li>Click "Download as <i className="fa fa-caret-right" />"</li>
            <li>Select "Comma-Separated Values (.csv, current sheet)"</li>
            <li>Chose location and file name to save file</li>
            <li>Click "Save"</li>
          </ol>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="columns-content"
          id="columns-header"
        >
          CSV Columns
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" paragraph>
            The column order is not important, but the header must be as described below. Optional columns need
            not be present or may be blank if the column exists.
          </Typography>

          <Typography component="ul" variant="body2">
            <li>number -- Student ID #</li>
            <li>first_name -- Student's First Name</li>
            <li>last_name -- Student's Last Name</li>
            <li>email -- Email Address, ex: user@domain.com</li>
            <li>phone -- <em>(Optional)</em> Student's Phone number</li>
            <li>program -- <em>(Optional)</em> One of
              <ul>
                <li>MS</li>
                <li>MSBA</li>
                <li>SS</li>
              </ul>
            </li>
            <li>subject -- <em>(Optional)</em> If Program is SS, One of
              <ul>
                <li>Social Science</li>
                <li>Mathematics</li>
                <li>English</li>
                <li>Science</li>
                <li>Art</li>
                <li>Music</li>
                <li>World Languages</li>
              </ul>
            </li>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="example-content"
          id="example-header"
        >
          Example.csv <DownloadIcon />
        </AccordionSummary>
        <AccordionDetails>
          <p>Download an example file here.</p>
          <a download="example.csv"
             href="data:text/csv;charset=UTF-8,number,first_name,last_name,email,phone,program,subject%0A123456789,Jane,Doe,person@domain.com,123-456-7890,SS,Math"
             className="btn btn-primary">
            Example.csv <DownloadIcon />
          </a>
        </AccordionDetails>

      </Accordion>

      {/*{environment === 'dev' ? (*/}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="random-csv-content"
          id="random-csv-header"
        >
          Random-People.csv <DownloadIcon />
        </AccordionSummary>
        <AccordionDetails>
          <p>Download an file of random people.</p>
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="non-uci email addr."
            sx={{ display: 'block' }}
            variant={'filled'}
            margin={'dense'}
            label={'Email'}
          />
          <Button onClick={() => getPeople(10, email)}>
            random-people.csv <DownloadIcon />
          </Button>
        </AccordionDetails>

      </Accordion>
      {/*) : null}*/}
    </>
  )
}



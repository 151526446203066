import { ApolloClient, ApolloProvider, createHttpLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext }                                                            from '@apollo/client/link/context';
import { onError }                                                               from '@apollo/client/link/error';
import { dataEndpoint }                              from '../config';
import { throwAuthErrorEvent }                                                   from './auth';
import { getToken, resetToken }                                                  from './auth/token';

// import { getToken, registerPostLogout }                                from '../providers/auth';


const GQL_ENDPOINT = createHttpLink({
  uri: dataEndpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      if (extensions.code === 'UNAUTHENTICATED') {
        console.warn('GQL Authentication Failed');
        console.warn(message);
        throwAuthErrorEvent(message.replace(/Context creation failed: /, ''));
      }
    });
  }

  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

const merge = (existing, incoming, { args }) => {
  const skip = (args.meta && args.meta.skip) || 0;

  // Slicing is necessary because the existing data is
  // immutable, and frozen in development.
  const merged = existing ? existing.slice(0) : [];
  for (let i = 0; i < incoming.length; ++i) {
    merged[skip + i] = incoming[i];
  }
  return merged;
}

export const client = new ApolloClient({
  link: HttpLink.from([
    authLink,
    errorLink,
    GQL_ENDPOINT,
  ]),
  // link:  GQL_ENDPOINT,
  cache: new InMemoryCache({
    typePolicies: {
      // Query: {
      //   fields: {
      //     // purchases:     {
      //     //   // Don't cache separate results based on
      //     //   // any of this field's arguments.
      //     //   keyArgs: false,
      //     //   // Concatenate the incoming list items with
      //     //   // the existing list items.
      //     //   merge,
      //     // },
      //     // purchase:      {
      //     //   keyArgs: [ 'id' ],
      //     // },
      //     // purchaseItems: {
      //     //   // Don't cache separate results based on
      //     //   // any of this field's arguments.
      //     //   keyArgs: false,
      //     //   // Concatenate the incoming list items with
      //     //   // the existing list items.
      //     //   merge,
      //     // },
      //   }
      // },

      // Student:     {
      //   keyFields: [ 'id' ],
      // },
      // Timeslot:        {
      //   keyFields: [ 'id' ],
      // },
      // Day: {
      //   keyFields: [ 'day' ],
      // },
      // Invite: {
      //   keyFields: [ 'id' ],
      // },
      // Setting: {
      //   keyFields: [ 'id' ],
      // },
      // PurchaseFund: {
      //   fields:    { // Field policy map for the Product type
      //     percent100: { // Field policy for the isInCart field
      //       read (_, input ) { // The read function for the isInCart field
      //         console.log(input)
      //         return 100;
      //       }
      //     }
      //   }
      // }

    },
  })
});

// registerPostLogout(() => client.resetStore())

export function onLogout () {
  return client.resetStore();
}

export default function Apollo ({ children, ...props }) {
  return (
    <ApolloProvider client={client} {...props} children={children} />
  );
}

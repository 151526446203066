import { gql }              from '@apollo/client';
import { graphql } from '../client';

export const GQL_MUTATION_NEW_TIMESLOT = gql`
  mutation addTimeslot($input: CreateTimeslotInput!) {
    timeslot: createTimeslot(input: $input) {
      id
    }
  }
`;

export const GQL_MUTATION_SET_TIMESLOT = gql`
  mutation updateTimeslot($timeslot: UpdateTimeslotInput!) {
    updateTimeslot(input: $timeslot) {
      id
    }
  }
`;

export const GQL_MUTATION_DELETE_TIMESLOT = gql`
  mutation deleteTimeslot($id: ID!) {
    deleteTimeslot(id: $id) {
      id
    }
  }
`;

import Avatar                 from '@mui/material/Avatar';
// import Badge               from '@mui/material/Badge';
// import NotificationsIcon   from '@mui/icons-material/Notifications';
import IconButton             from '@mui/material/IconButton';
import Link                   from '@mui/material/Link';
import Menu                   from '@mui/material/Menu';
import MenuItem               from '@mui/material/MenuItem';
import { useState }           from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth }            from '../Context/auth';


export default function UserIcon ({sx = {}}) {
  const [ anchorEl, setAnchorEl ]              = useState(null);
  const { user, logout, loading: authLoading } = useAuth();

  const handleClick  = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose  = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
    handleClose();
  };

  const initials = (
    authLoading ? '..' : user.firstName.slice(0, 1) + user.lastName.slice(0, 1)
  ).toUpperCase()


  return (
    <div sx={sx}>

      <IconButton
        color="inherit"
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        sx={{ pr: 0 }}
      >
        {/*<Badge badgeContent={4} color="secondary">*/}
        <Avatar
          sx={{
            color:   'primary.main',
            bgcolor: 'secondary.main',
            height:  { xs: '32px', sm: '40px' },
            width:   { xs: '32px', sm: '40px' },
          }}
          alt={authLoading ? '..' : user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
          // src={user.person.photo}
        >
          {initials}
        </Avatar>
        {/*</Badge>*/}
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/*<MenuItem component={Link} to='/users'>Users</MenuItem>*/}
        {/*<MenuItem onClick={handleClose} component={RouterLink} to="/settings">*/}
        {/*  Settings*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

    </div>
  );
}

import camelcase        from 'camelcase';
import { cloneElement } from 'react';

Array.prototype.joinElements = function joinElements (glue) {
  if (this.length <= 1) {
    // this this instead of direct return to propagate key property consistently
    return this.map((item, i) => cloneElement(item, { key: i }));
  }
  let out = [];
  this.forEach((item, i) => {
    if (i > 0) {
      out.push(cloneElement(glue, { key: `${i}-divider` }));
    }
    out.push(cloneElement(item));
  })
  return out;
}

Array.prototype.keyedIndexOf = function keyedIndexOf (key, needle) {
  let outIndex = -1;
  for (let i = 0; i < this.length; i++) {
    if (this[i][key] === needle) {
      outIndex = i;
      break;
    }
  }
  return outIndex;
}

/**
 * @param {Boolean} [prependKey] add object prop name before child props and camelcase
 * @param {Array<String>} [selectedKeys] keys to include, ignoring others. Will do all, if empty
 */
Array.prototype.flattenObjects = function flattenObjects (prependKey, selectedKeys) {
  return this.map(o => {
    const out = { ...o };

    // foreach property
    (selectedKeys ?? Object.getOwnPropertyNames(out))
      .forEach(key => {

        // if it's an object
        if (typeof out[key] === 'object' && !Array.isArray(out[key])) {
          // get keys
          Object.getOwnPropertyNames(out[key]).forEach(key2 => {
            const tempKey = prependKey ? camelcase(key + '-' + key2) : key2;

            // attach to parent
            out[tempKey] = out[key][key2];
          })
        }
      })

    return out;
  });
}



Array.prototype.sortBy = function (key, direction = 1) {
  return [ ...this ].sort(({ [key]: a }, { [key]: b }) => {
    if (a > b) return 1 * direction;
    if (a < b) return -1 * direction;
    return 0;
  })
}

import Typography from '@mui/material/Typography';

export default function Footer () {
  return (
    <Typography variant={'body1'} paragraph color="textSecondary" align="center" maxWidth='sm'>
      To change your submitted timeslot, communicate technical
      problems, or request a new invitation, please
      email <a href='mailto:mat-interviews@uci.edu' target='_blank'>MAT-Interviews@uci.edu</a>
    </Typography>
  );
}

import { useSnackbar } from 'notistack';

export default function useSubmitSnack (options = {}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const defaults = {
    ...options
  }
  let _snack = null;



  const start = (msg = 'Saving Changes', options = {}) => {
    return _snack = enqueueSnackbar(msg, {
      variant: 'default',
      ...defaults,
      ...options
    });
  }

  const success = (msg = 'Changes Saved!', options = {}) => {
    closeSnackbar(_snack);
    return _snack = enqueueSnackbar(msg, {
      variant: 'success',
      ...defaults,
      ...options
    });
  }

  const info = (msg = 'Some Info!', options = {}) => {
    closeSnackbar(_snack);
    return _snack = enqueueSnackbar(msg, {
      variant: 'info',
      ...defaults,
      ...options
    });
  }

  const error = (msg = 'Couldn\'t save changes', options = {}) => {
    closeSnackbar(_snack);
    return _snack = enqueueSnackbar(msg, {
      variant: 'error',
      ...defaults,
      ...options
    });
  }

  const catchError = (msg, options) => {
    return (err) => {
      console.error(err.stack);
      error(msg, options);
    }
  }


  return {
    start, success, error, catchError, info
  }
}

import { gql }                      from '@apollo/client';
import { graphql } from '../client';

export const GQL_QUERY_GET_SETTINGS = gql`
  query getSettings ($keys: [ID!]!) {
    getSettings (keys: $keys) {
      key
      label

      type
      value
      
      adminOnly
      
      createdAt
      updatedAt
    }
  }
`;
export const GQL_QUERY_GET_SETTING = gql`
  query getSetting ($key: ID!) {
    getSetting (key: $key) {
      key
      label

      type
      value
      
      adminOnly
      
      createdAt
      updatedAt
    }
  }
`;
export const GQL_QUERY_LIST_SETTINGS = gql`
  query listSettings ($filter: SettingFilterInput) {
    listSettings (filter: $filter) {
      items {
        key
        label
        
        type
        value
        
        adminOnly
        
        createdAt
        updatedAt
      }
    }
  }
`;
export const GQL_QUERY_TEST_HBS_TEMPLATE = gql`
  query testHbsCompile ($template: String!, $context: JSON) {
    test: testHbsCompile (context: $context, template: $template) {
      context
      text
    }
  }
`;

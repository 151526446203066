import Grid                                                                   from '@mui/material/Grid';
import Paper                                                                  from '@mui/material/Paper';
import * as PropTypes                                                         from 'prop-types';
import { useEffect, useState }                                                from 'react';
import { useLocation }                                                        from 'react-router-dom';
import { graphql }                                                            from '../data/client';
import { GQL_QUERY_INVITE_FOR_REGISTRATION, GQL_QUERY_VALIDATE_INVITE_TOKEN } from '../data/register';
import { GQL_MUTATION_UPDATE_STUDENT_REGISTRATION }                           from '../data/register/mutations';
import useSubmitSnack                                                         from '../lib/snacks';
import stringToObject                                                         from '../utils/stringToObject';
import Completed                                                              from './Completed';
import HasInvite                                                              from './HasInvite';
import { PreviouslyCompleted }                                                from './PreviouslyCompleted';
import Unauth                                                                 from './Unauth';

export default function Register () {
  const [ stage, setStage ]     = useState('unauth');
  const [ keyData, setKeyData ] = useState({ key: '', email: '' });
  const [ invite, setInvite ]   = useState({ student: {}, timeslot: null });
  const location                = useLocation();
  const snack = useSubmitSnack();

  console.log({ keyData, invite, stage });

  useEffect(() => {
    // console.log(location)
    if (location.hash) {
      const values = stringToObject(location.hash.replace(/^#/, ''));
      // console.log(values)
      if (values.k) {
        graphql({
          query:     GQL_QUERY_VALIDATE_INVITE_TOKEN,
          variables: { token: values.k }
        }).then(({ data }) => {
          if (data.payload) {
            const { key = '', email = '' } = data.payload;
            setKeyData(k => ({ ...k, key, email }));
            setStage('')
          }

        })
          .catch(err => {
            console.error(err);
          })
      }
    }
  }, [ location ])

  useEffect(() => {
    if (keyData.key && keyData.email) {
      graphql({
        query:     GQL_QUERY_INVITE_FOR_REGISTRATION,
        variables: keyData
      }).then(({ data }) => {
        const inv = data.invite;
        setInvite(inv)
        if (inv.complete) {
          setStage('previously-completed');
        } else {
          setStage('has-invite')
        }

      })
        .catch(err => {
          snack.error(`No Invitation Found with Key and Email: ${keyData.email}, ${keyData.key}`)
        })
      ;
    }

  }, [ keyData ])

  const handleUnauthSubmit = ({ key, email }) => {
    setKeyData(k => ({ ...k, key, email }));
  }
  const handleStudentSubmit = async (student) => {
    console.log('submitting', {student})
    snack.start('Saving Registration');
    graphql({
      mutation:  GQL_MUTATION_UPDATE_STUDENT_REGISTRATION,
      variables: {
        ...keyData,
        student
      }
    })
      .then(({ data }) => {
        snack.success('Registration Updated Successfully!');
        setInvite(data.register)
        setStage('completed');
      })
      .catch(snack.catchError);
  }


  switch (stage) {
    case 'has-invite':
      return <HasInvite invite={invite} student={invite.student} onSubmit={handleStudentSubmit} />;
    case 'previously-completed':
      return <PreviouslyCompleted invite={invite} />;
    case 'completed':
      return <Completed invite={invite} student={invite.student} timeslot={invite.student.timeslot} />;
    case 'unauth':
    default:
      return <Unauth onSubmitKey={handleUnauthSubmit} />;
  }
}

import { ListItem, ListItemText } from '@mui/material';
import List                       from '@mui/material/List';
import Paper                      from '@mui/material/Paper';
import Typography                 from '@mui/material/Typography';
import * as PropTypes             from 'prop-types';


PreviouslyCompleted.propTypes = {
  invite: PropTypes.shape({
    student: PropTypes.shape({})
  })
};

export function PreviouslyCompleted ({ invite }) {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant={'h5'} paragraph>
        Invitation already complete
      </Typography>
      <Typography paragraph>
        Thank you for registering for a time to interview with UCI School of Education. Our records indicate this
        invitation has has already been visited and a timeslot has been selected. You are unable to re-register with
        this invitation.
      </Typography>
      <Typography paragraph>
        If you need to change your registration, did not register, or think you've reached this page in error, please
        email <a href="mailto:mat-interviews@uci.edu">Mat-Interviews@uci.edu</a>.
      </Typography>

      <Typography variant={'h5'} sx={{ pt: 2 }}>
        Invitation Data
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary={invite.key} secondary="Key" />
        </ListItem>
        <ListItem>
          <ListItemText primary={invite.email} secondary="Email" />
        </ListItem>
      </List>
    </Paper>
  );
}

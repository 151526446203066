import styled        from '@emotion/styled';
import { AppBar }    from '@mui/material';
import Box           from '@mui/material/Box';
import Container     from '@mui/material/Container';
import Divider       from '@mui/material/Divider';
import Toolbar       from '@mui/material/Toolbar';
import Typography    from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// import UserIcon           from './UserIcon';

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width:           '100%',
  color:           theme.palette.common.white,
  // padding:           theme.spacing(4, 4, 1, 4),
  borderBottomColor: theme.palette.secondary.main,
  borderBottomWidth: theme.spacing(0.75),
  borderBottomStyle: 'solid',
  marginBottom: theme.spacing(2),
}))

const HeaderTypography    = styled(Typography)(({ theme }) => ({
  fontFamily: 'arial',
  lineHeight: 1,
  fontSize:   '4em',
  fontWeight: 700
}))
const SubheaderTypography = styled(HeaderTypography)(({ theme }) => ({
  fontSize: '1.75em',
  padding:  theme.spacing(0.5, 1.75, 0, 1.75),
  fontWeight: 'normal'
}))
// const VertDivider = styled(Divider)(({ theme }) => ({
//   backgroundColor: theme.palette.common.white
//   height
// }))


export default function MenuBar ({ nav: Nav }) {
  // const theme = useTheme();
  // const isXS = useMediaQuery(theme => theme.breakpoints.only('xs'));
  // const isDrawerOpen = useReactiveVar(drawerOpen);
  // const setOpen = useSetRecoilState(drawerOpenAtom);

  // const handleDrawerToggle = () => {
  //   // setOpen(o => !o);
  //   drawerOpen(!isDrawerOpen)
  // };


  return (
    <Root sx={{ displayPrint: 'none' }}>
      <Container maxWidth="md" sx={{ px: { xs: 0, sm: 1 }, pt: { xs: 0, sm: 2 } }} disableGutters>

        <Box display={{ xs: 'none', sm: 'flex' }}>
          <HeaderTypography variant="h2">UCI</HeaderTypography>

          <SubheaderTypography variant="h3">School of<br />Education</SubheaderTypography>
          <Divider orientation="vertical" variant="middle" flexItem
                   sx={{ backgroundColor: 'white', width: '2px' }} />
          <SubheaderTypography variant="h3">MAT Admission<br />Interviews</SubheaderTypography>
        </Box>

        {Nav ? (
          <AppBar position="static" elevation={0}>
            {/*<Container maxWidth="xl">*/}
            <Toolbar disableGutters>
              <Nav />
            </Toolbar>
            {/*</Container>/*/}
          </AppBar>
        ) : null}

      </Container>
    </Root>
  );
}

import { useQuery }                                                         from '@apollo/client';
import CheckIcon                                                            from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon
                                                                            from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronRightIcon                                                     from '@mui/icons-material/ChevronRight';
import DoneIcon                                                             from '@mui/icons-material/Done';
import PendingActionsIcon                                                   from '@mui/icons-material/PendingActions';
import RotateLeftIcon                                                       from '@mui/icons-material/RotateLeft';
import SendIcon                                                             from '@mui/icons-material/Send';
import VisibilityIcon                                                       from '@mui/icons-material/Visibility';
import VisibilityOffIcon                                                    from '@mui/icons-material/VisibilityOff';
import WebIcon                                                              from '@mui/icons-material/Web';
import WebAssetOffIcon                                                      from '@mui/icons-material/WebAssetOff';
import { Tooltip }                                                          from '@mui/material';
import Button                                                               from '@mui/material/Button';
import Link                                                                 from '@mui/material/Link';
import Typography                                                           from '@mui/material/Typography';
import { GridActionsCellItem as MuiGridActionsCellItem }                    from '@mui/x-data-grid'
import { useEffect, useState }                                              from 'react';
import { graphql }                                                          from '../data/client';
import { GQL_MUTATION_CREATE_INVITE }                                       from '../data/invite';
import { GQL_MUTATION_RESEND_CONFIRM_EMAIL }                                from '../data/register/mutations';
import { GQL_QUERY_GET_STUDENTS, GQL_QUERY_RESEND_INVITE_EMAIL_BY_STUDENT } from '../data/student';
import { useDialogCtrl }                                                    from '../lib/Form';
import { DataGrid, FiltersToolbar, GridLoadingOverlay }                     from '../lib/gridComponents';
import useSubmitSnack                                                       from '../lib/snacks';
import useSearchParamsSync                                                  from '../lib/useSearchParamsSync';
import StudentFilterDrawer                                                  from './components/StudentFilters';


const programMap = {
  'Single Subject':                              'SS',
  'Multiple Subject':                            'MS',
  'Multiple Subject -- Bilingual Authorization': 'MSBA',
}

const columns = [
  { field: 'uciStudentNum', headerName: 'Student #' },
  { field: 'firstName', headerName: 'First' },
  { field: 'lastName', headerName: 'Last' },
  { field: 'program', headerName: 'Pgrm', valueFormatter: ({ value }) => programMap[value] ?? '--' },
  { field: 'subject', headerName: 'Subj' },
  {
    field:       'inviteSeen',
    headerName:  'Seen',
    type:        'boolean',
    hide:        true,
    sortable:    false,
    valueGetter: ({ row: { inviteStatus } }) => inviteStatus.seen,
    renderCell:  tooltipBooleanCol({
      valueFormatter: (value) => value ? 'Has Seen Invite Email' : 'Has Not Seen Invite Email',
      trueIcon:       <VisibilityIcon color="success" />,
      falseIcon:      <VisibilityOffIcon color="disabled" />,
    })
  },
  {
    field:       'inviteVisited',
    headerName:  'Visited',
    type:        'boolean',
    hide:        true,
    sortable:    false,
    valueGetter: ({ row: { inviteStatus } }) => inviteStatus.visited,
    renderCell:  tooltipBooleanCol({
      valueFormatter: (value) => value ? 'Has Visited Website' : 'Has Not Visited Website',
      trueIcon:       <WebIcon color="success" />,
      falseIcon:      <WebAssetOffIcon color="disabled" />,
    })
  },
  {
    field:      'inviteComplete',
    headerName: 'Invite Complete',
    type:       'boolean',
    // hide:        true,
    sortable:    false,
    valueGetter: ({ row: { inviteStatus } }) => inviteStatus.complete,
    renderCell:  tooltipBooleanCol({
      valueFormatter: (value) => value ? 'Has Completed Registration' : 'Has Not Completed Registration',
      trueIcon:       <DoneIcon color="success" />,
      falseIcon:      <PendingActionsIcon color="disabled" />,
    })
  },
  // {
  //   field:      'inviteOpened',
  //   headerName: 'Invite Opened',
  //   type:       'boolean',
  //   hide:       true,
  //   renderCell: tooltipBooleanCol({
  //     valueFormatter: (value) => value ? formatDate(value, 'MMMM do, yyyy', new Date()) : 'Not Opened',
  //     trueIcon:       <VisibilityIcon color="success" />,
  //     falseIcon:      <VisibilityOffIcon />
  //   })
  // },
  {
    field:      'actions',
    headerName: 'Actions',
    type:       'actions',
    flex:       1,
    hideable:   false,
    align:      'right',
    getActions: ({ id, row, ...params }) => {
      // console.log(params)
      return [
        <GridActionsCellItem icon={<ChevronRightIcon />} href={`/students/${row.id}`} label="Go to Student" />,
        // <GridActionsCellItem icon={<ChevronRightIcon />} href={`/students/${id}/update`} label="Update" showInMenu />,
        // <GridActionsCellItem icon={<ChevronRightIcon />} href={`/students/${id}/logs`} label="Logs" showInMenu />,
        // <GridActionsCellItem icon={<ChevronRightIcon />} href={`/students/${id}/history`} label="History" showInMenu />,
      ]
    }
  }
];

const defaultPagination = {
  page:  1,
  limit: 25
};
const defaultSort       = {
  field: 'lastName',
  sort:  'asc'
};
const defaultFilter     = {
  // status: 'ordered',
};

const translateStateToParams = (data) => {
  return Object
    .getOwnPropertyNames(data)
    .reduce((obj, k) => ({ ...obj, ...data[k] }), {})
    ;
}
const translateParamsToState = (data) => {
  const { sort, field, page, limit, ...rest } = data;
  return {
    sort:       { sort, field },
    pagination: { page: Number(page), limit: Number(limit) },
    filter:     rest
  }
}

export default function ListStudents () {
  const { searchState: { sort: _sort, pagination: _page, filter: _filter }, updateSearchParams } = useSearchParamsSync({
    translateStateToParams,
    translateParamsToState,
    defaultState: {
      filter:     defaultFilter,
      sort:       defaultSort,
      pagination: defaultPagination
    },
  });
  const [ selectionModel, setSelectionModel ]                                                    = useState([]);
  const confirmDialogCtrl                                                                        = useDialogCtrl();
  const snacks = useSubmitSnack();


  const [ filterDrawerOpen, setFilterDrawerOpen ] = useState(false);
  const [ filter, setFilter ]                     = useState(_filter);
  const [ sort, _setSort ]                        = useState(_sort);
  const [ pagination, setPagination ]             = useState(_page);

  const { data: studentData, loading, error, refetch } = useQuery(GQL_QUERY_GET_STUDENTS, {
    variables: {
      filter: defaultFilter,
      sort:   {
        field:     defaultSort.field,
        direction: defaultSort.sort === 'asc' ? 1 : -1
      },
      page:   defaultPagination
    }
  });

  useEffect(() => {
    refetch({
      page: pagination,
      filter,
      sort: {
        field:     sort.field,
        direction: sort.sort === 'asc' ? 1 : -1
      },
    })
    updateSearchParams({
      filter, sort, pagination
    })
  }, [ filter, sort, pagination, refetch ])

  if (error) {
    console.error(error.stack);
    return null;
  }

  const { items: students, meta } = studentData ? studentData.listStudents : {};

  const setSort = (newSort = []) => {
    console.log(newSort)
    const { field = defaultSort.field, sort = defaultSort.sort } = newSort[0] || {};
    _setSort({
      field,
      sort
    })
  };


  const confirmBulkAction = (action) => {
    const instr = bulkAction(action, selectionModel);
    confirmDialogCtrl.openDialog({
      title:    instr.label,
      children: <Typography>{instr.description}</Typography>,
      actions:  (
                  <>
                    <Button variant={'contained'} autoFocus onClick={() => confirmDialogCtrl.onClose()}>Cancel</Button>
                    <Button variant={'outlined'} onClick={() => { doBulkAction(action); confirmDialogCtrl.onClose() }}>Confirm</Button>
                  </>
                )

    })
  }
  const doBulkAction      = async (action) => {
    const instr = bulkAction(action, selectionModel);
    snacks.start(`Sending ${selectionModel.length} ${instr.noun}`);

    const proms = selectionModel.map(s => instr.func(s));
    const results = await Promise.allSettled(proms);
    console.log(results)
    const stats = results.reduce((obj, res) => {
      if (res.status === 'rejected') {
        obj.failure++;
        console.error(res.reason);
      }
      if (res.status === 'fulfilled') {
        if (res.value.data.success) {
          obj.success++;
        } else {
          obj.incomplete++;
        }
      }
      return obj;
    }, {
      success: 0,
      failure: 0,
      incomplete: 0
    });

    snacks.success(`${stats.success} successfully send, ${stats.incomplete} did not send, ${stats.failure} failed to send.`);
    refetch({
      page: pagination,
      filter,
      sort: {
        field:     sort.field,
        direction: sort.sort === 'asc' ? 1 : -1
      },
    })

  }


  // console.log(sort)

  return (
    <>

      {confirmDialogCtrl.open ? confirmDialogCtrl.getDialog() : null}

      {filterDrawerOpen && (
        <StudentFilterDrawer
          filter={filter}
          setFilter={setFilter}
          open={filterDrawerOpen}
          onClose={() => setFilterDrawerOpen(false)}
        />
      )}

      <DataGrid
        loading={loading}
        columns={columns}
        rows={students}
        rowCount={meta && meta.count}
        sortModel={[ sort ]}
        setSort={setSort}

        setPagination={setPagination}
        pageSize={pagination.limit}

        checkboxSelection
        onSelectionModelChange={setSelectionModel}
        selectionModel={selectionModel}


        components={{
          Toolbar:        FiltersToolbar,
          LoadingOverlay: GridLoadingOverlay,
          // NoRowsOverlay:  NoRowsOverlay,
        }}
        componentsProps={{
          toolbar: {
            onFilterClick:      () => setFilterDrawerOpen(true),
            textSearch:         filter.text || '',
            onTextSearchChange: (newText) => setFilter(f => ({ ...f, text: newText })),
            actions:            selectionModel.length ? [
              {
                text:    'Resend Invite Emails',
                icon:    <RotateLeftIcon />,
                onClick: () => confirmBulkAction('resend-invite')
              },
              { text: 'Create New Invites', icon: <SendIcon />, onClick: () => confirmBulkAction('new-invite') },
              {
                text:    'Resend Confirmation Emails',
                icon:    <RotateLeftIcon />,
                onClick: () => confirmBulkAction('resend-confirm')
              },
            ] : null
          }
        }}
      />
    </>
  );
}


function GridActionsCellItem (
  {
    icon,
    href,
    label,
    ...rest
  }
) {

  let ttpIcon = (
    <Tooltip title={label}>
      {icon}
    </Tooltip>
  );

  return (
    <MuiGridActionsCellItem
      icon={ttpIcon}
      component={Link}
      href={href}
      label={label}
      {...rest}
    />
  );
}

function tooltipBooleanCol (
  {
    valueFormatter = (value) => value ? 'true' : 'false',
    trueIcon = <CheckIcon />,
    falseIcon = <CheckBoxOutlineBlankIcon />
  }
) {
  return function TooltipBooleanCol ({ value }) {
    return (
      <Tooltip title={valueFormatter(value)}>
        {value ? trueIcon : falseIcon}
      </Tooltip>
    );
  }
}


const bulkAction = (action, selections) => {
  let instructions = null;
  switch (action) {
    case 'new-invite':
      instructions = {
        func:        sendInvite,
        label:       'Send New Invitations',
        description: `You are about to send ${selections.length} email(s). Please Confirm.`,
        noun: 'invitation email(s)'
      };
      break;
    case 'resend-invite':
      instructions = {
        func:        resendInviteEmail,
        label:       'Resend Latest Invitations',
        description: `You are about to send ${selections.length} email(s). Please Confirm.`,
        noun: 'invitation email(s)'
      };
      break;
    case 'resend-confirm':
      instructions = {
        func:        resendConfirmEmail,
        label:       'Resend Confirmations',
        description: `You are about to send ${selections.length} email(s). Please Confirm.`,
        noun: 'confirmation email(s)'
      };
      break;
    default:
      throw new Error('No action provided')
  }
  return instructions;
}



const sendInvite         = (id) => {
  return graphql({
    mutation:  GQL_MUTATION_CREATE_INVITE,
    variables: { student: id, sendEmail: true }
  })
    .then(({ data }) => ({ data: { success: true } }))
}
const resendConfirmEmail = (id) => {
  return graphql({
    mutation:  GQL_MUTATION_RESEND_CONFIRM_EMAIL,
    variables: { student: id }
  })
}
const resendInviteEmail  = (id) => {
  return graphql({
    query:     GQL_QUERY_RESEND_INVITE_EMAIL_BY_STUDENT,
    variables: { student: id }
  })
}

import { Box, Collapse }              from '@mui/material';
import Button                         from '@mui/material/Button';
import Paper                          from '@mui/material/Paper';
import Typography                     from '@mui/material/Typography';
import { useEffect }                  from 'react';
import { useSettings }                from '../../data/setting';
import { Select, TextField, useForm } from '../../lib/Form';
import SelectTimeslot                 from './SelectTimeslot';


export default function StudentForm ({ student, sx = {}, onSubmit }) {
  const {
          loading: settingsLoading,
          error:   settingsError,
          programs,
          subjects
        } = useSettings([ 'programs', 'subjects' ])

  const {
          getField,
          getValue,
          setValue,
          hasErrors,
          isValid,
          handleSubmit,
          clearErrors,
          unsetValue
        } = useForm({
    defaultValues: {
      id:            student.id,
      uciStudentNum: student.uciStudentNum,
      firstName:     student.firstName,
      lastName:      student.lastName,
      email:         student.email,
      phone:         student.phone || '',
      program:       student.program || '',
      subject:       student.subject || '',
      timeslotId:    student.timeslotId || null,
    },
    rules:         {
      firstName:  { trim: false, required: true },
      lastName:   { trim: false, required: true },
      email:      { isEmail: true, required: true },
      phone:      { isPhone: true, trim: false, required: true },
      program:    { required: true },
      subject:    { required: (vals) => vals.program === 'Single Subject' },
      timeslotId: { required: true }
    },
  });

  const program = getValue('program')
  useEffect(() => {
    clearErrors('subject');
    if (program !== 'Single Subject') {
      unsetValue('subject');
    }
  }, [ program ]);

  const canSubmit = !hasErrors && isValid;
  // console.log({ canSubmit, hasErrors, isValid })

  const handleSelectTimeslot = (slot) => {
    setValue('timeslotId', ts => ts === slot ? null : slot);
  }

  return (
    <>
      <Paper sx={{ p: 3, mb: 3, ...sx }}>
        <Typography variant="h3" color="primary" paragraph>
          Student Information
        </Typography>
        <Typography variant="body1" paragraph>
          Please confirm your data below. Correct anything that is inaccurate. If you prefer a different email or
          phone number, enter those instead.
        </Typography>

        <TextField {...getField('firstName')} label="First Name" fullWidth margin={'dense'} />
        <TextField {...getField('lastName')} label="Last Name" fullWidth margin={'dense'} />
        <TextField {...getField('email')} label="Email Address" fullWidth margin={'dense'} />
        <TextField {...getField('phone')} label="Phone Number" fullWidth margin={'dense'} />

        {settingsLoading ? (null) : (
          <>
            <Select
              {...getField('program')}
              selectSx={{ minWidth: '100%' }}
              fullWidth
              margin={'dense'}
              options={programs ? programs.value.map(({ value }) => ({ value, label: value })) : []}
            />

            <Collapse in={getValue('program') === 'Single Subject'}>
              <Select
                {...getField('subject')}
                selectSx={{ width: '100%' }}
                fullWidth
                margin={'dense'}
                options={subjects ? subjects.value.map(({ value }) => ({ value, label: value })) : []}
              />
            </Collapse>
          </>
        )}

      </Paper>
      <SelectTimeslot
        sx={{ mb: 3 }}
        selectedTimeslot={getValue('timeslotId')}
        onChange={handleSelectTimeslot}
      />


      <Box textAlign="center">
        <Button
          variant="contained"
          disabled={!canSubmit}
          sx={{ width: { xs: '100%', sm: '75%', md: '50%' } }}
          onClick={handleSubmit(onSubmit)}
        >
          {/* TODO HERE I AM */}
          Submit Reservation
        </Button>
      </Box>
    </>
  );

}

import { Box, FormControl, FormControlLabel, TextField as MuiTextField }    from '@mui/material';
import Divider                                                              from '@mui/material/Divider';
import Grid                                                                 from '@mui/material/Grid';
import { DatePicker, StaticDatePicker, TimePicker }                         from '@mui/x-date-pickers';
import { addDays, addHours, differenceInHours, formatDuration, startOfDay } from 'date-fns';
import intervalToDuration                                                   from 'date-fns/intervalToDuration';
import parseDate                                                            from 'date-fns/parse';
import startOfToday                                                         from 'date-fns/startOfToday';
import { isMobile }                                                         from 'react-device-detect';
import { useNavigate }                                                      from 'react-router-dom';
import { graphql }                                                          from '../data/client';
import { GQL_MUTATION_NEW_TIMESLOT }                                        from '../data/timeslot';
import { FormRow, Switch, TextField, useForm }                              from '../lib/Form';
import { MenuButtonGroup }                                                  from '../lib/menuElements';
import useSubmitSnack                                                       from '../lib/snacks';


const endTimeValidator = (val, values) => {
  return val > values.start
}
endTimeValidator.message = 'End time must be AFTER Start time.'

export default function NewTimeslot () {
  const snack    = useSubmitSnack();
  const navigate = useNavigate();
  const now      = startOfToday()
  const day      = startOfDay(addDays(new Date(), 14));
  const start    = parseDate('10:00 am', 'hh:mm aaa', day);
  const end      = parseDate('02:00 pm', 'hh:mm aaa', day);

  const {
          getField,
          getValue,
          setValue: _setValue,
          handleSubmit,
          hasErrors,
          isValid,
          reset
        } = useForm({
    defaultValues: {
      day:    day,
      start:  start,
      end:    end,
      cutoff: addHours(day, -1 * 24 * 5),
      seats:  25,
      hidden: false
    },
    rules:         {
      day:    { required: true, isDate: true },
      start:  { required: true, isDate: true },
      end:    { required: true, isDate: true, validate: endTimeValidator },
      cutoff: { required: true, isDate: true },
      seats:  { required: true, isInt: true, min: 0 },
      hidden: { isBool: true }
    },
  });

  const setValue = (...args) => {
    console.log(...args);
    _setValue(...args);
  }

  const _doSubmit       = ({ start, end, cutoff, seats, hidden }) => {
    const timeslot = { start, end, cutoff, seats, hidden };

    snack.start('Saving Timeslot');
    return graphql({
      mutation:  GQL_MUTATION_NEW_TIMESLOT,
      variables: { input: timeslot }
    });

  }
  const onSubmit        = (values) => {
    _doSubmit(values)
      .then(({ data }) => {
        snack.success('Timslot Saved')
        navigate(`/timeslots/${data.timeslot.id}`)
      })
      .catch(snack.catchError)
    ;
  };
  const onSubmitAnother = (values) => {
    _doSubmit(values)
      .then(({ data }) => {
        snack.success('Timeslot Saved')
        // reset();
      })
      .catch((err) => snack.error(err.message))
  }

  const canSubmit = !hasErrors && isValid;
  let duration;
  try {
    duration = intervalToDuration({
      start: getValue('start'),
      end:   getValue('end')
    });
  } catch (err) {
    duration = null
  }

  const onDayChange = (day) => {
    console.log(getValue('day').getTime(), day.getTime())
    const diff = differenceInHours(day, getValue('day'));
    console.log('diff', diff)
    setValue('start', (s) => {
      let thing = addHours(s, diff);
      console.log(s.getTime(), thing.getTime())
      return thing;
    })
    setValue('end', (e) => {
      let thing = addHours(e, diff);
      console.log(e.getTime(), thing.getTime())
      return thing;
    })
    setValue('day', day)
  };

  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item sm={12} md={6} justifyContent={'center'}>
          <FormRow sx={{ textAlign: 'center', justifyContent: 'center' }}>
            <Box sx={{ borderWidth: 1, borderColor: 'divider', borderStyle: 'solid', display: 'inline-block' }}>
              <StaticDatePicker
                displayStaticWrapperAs={isMobile ? 'mobile' : 'desktop'}
                closeOnSelect={false}
                openTo="day"
                value={getValue('day')}
                onChange={onDayChange}
                minDate={now}
                renderInput={(params) => <MuiTextField {...params} />}
              />
            </Box>
          </FormRow>
        </Grid>


        <Grid item sm={12} md={6} width="100%" maxWidth="400px">
          <FormRow sx={{ textAlign: 'center' }}>
            <TimePicker
              label="Start Time"
              value={getValue('start')}
              minutesStep={5}
              onChange={(time) => setValue('start', time)}
              renderInput={(params) => <MuiTextField {...params} fullWidth />}
            />
          </FormRow>
          <FormRow sx={{ textAlign: 'center' }}>
            <TimePicker
              label="End Time"
              value={getValue('end')}
              minutesStep={5}
              onChange={(time) => setValue('end', time)}
              renderInput={(params) => <MuiTextField {...params} fullWidth />}
            />
          </FormRow>
          <FormRow>
            <DurationCheck maxHours={4} duration={duration} />
          </FormRow>
          <FormRow>
            <DatePicker
              label="Cutoff Time"
              value={getValue('cutoff')}
              maxDate={getValue('day')}
              minDate={now}
              onChange={(time) => setValue('cutoff', time)}
              renderInput={(params) => <MuiTextField {...params} fullWidth />}
            />
            {/*<TextField {...getField('cutoff')} label="Cutoff Time" fullWidth />*/}
          </FormRow>
          <FormRow>
            <TextField {...getField('seats')} label="# of Seats" fullWidth type="number" />
          </FormRow>
          <FormRow>
            <FormControl>
              <FormControlLabel
                label={`Hide from Students? (${getValue('hidden') ? 'Yes' : 'No'})`}
                control={
                  <Switch {...getField('hidden')} />
                }
              />
            </FormControl>
          </FormRow>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 3 }} />

      <Box textAlign="center">
        <MenuButtonGroup
          text="Add Timeslot"
          onClick={handleSubmit(onSubmit)}
          disabled={!canSubmit}
          sx={{ width: { xs: '100%', sm: '75%', md: '50%' } }}
          mainButtonProps={{ sx: { flexGrow: 1 } }}
          anchorOrigin={{
            vertical:   'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical:   'bottom',
            horizontal: 'right',
          }}
          options={[
            {
              text:     'Save and Add Another',
              disabled: !canSubmit,
              onClick:  handleSubmit(onSubmitAnother)
            },
            { divider: true },
            { text: 'Reset Form', onClick: () => reset() }
          ]}
        />
      </Box>
    </>
  );
}

function DurationCheck ({ duration, maxHours, sx }) {
  let props   = {};
  let warning = false;

  if (!duration || duration.hours > maxHours) {
    props   = {
      ...props,
      color:      'warning',
      focused:    true,
      inputProps: { readOnly: true },
      disabled:   false,
      helperText: 'Long duration: This may be an error.'
    }
    warning = true;
  }

  return (
    <MuiTextField
      label={'Duration'}
      value={
        duration
        ? formatDuration(duration)
        : 'Invalid Time(s)'
      }
      disabled
      {...props}
      fullWidth
      sx={sx}
    />
  );
}

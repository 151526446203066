import Button                 from '@mui/material/Button';
import Container              from '@mui/material/Container';
import Grid                   from '@mui/material/Grid';
import Paper                  from '@mui/material/Paper';
import Typography             from '@mui/material/Typography';
import { TextField, useForm } from '../lib/Form';

export default function Unauth ({ onSubmitKey }) {
  const { getField, isValid, hasErrors, handleSubmit } = useForm({
    defaultValues: {
      key:   '',
      email: '',
    },
    rules:         {
      key:   { uppercase: true, required: true },
      email: { isEmail: true, required: true }
    },
  });

  const canSubmit = !hasErrors && isValid;


  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item>
        <Paper sx={{ p: 2 }}>

          <Typography variant={'h5'} paragraph>
            Welcome
          </Typography>

          <Typography variant={'body1'} paragraph>
            Thank you for applying to UCI School of Education MAT Program. You may register here for an Interview.
          </Typography>

        </Paper>
      </Grid>
      <Grid item>
        <Paper sx={{ p: 2 }}>

          <Typography variant={'h5'} paragraph>
            Registration
          </Typography>
          <Typography variant={'body1'} paragraph>
            To register, please fill in the form below with the Key you received in your invitation and the email
            address where you received the invitation.
          </Typography>

          <Container maxWidth="xs">
            <TextField {...getField('email')} fullWidth margin={'dense'} />
            <TextField {...getField('key')} fullWidth margin={'dense'} />

            <Button variant={'contained'} fullWidth sx={{ mt: 2 }} disabled={!canSubmit} onClick={handleSubmit(onSubmitKey)}>
              Begin
            </Button>
          </Container>

        </Paper>
      </Grid>
    </Grid>
  )
}

import { gql }                      from '@apollo/client';
import { graphql } from '../client';

export const GQL_QUERY_REDIRECT_TOKEN = gql`
  query RedirectUrlQuery($url: String!) {
    token: redirectUrl(url: $url)
  }
`;

export const GQL_QUERY_PROFILE = gql`
  query getProfile {
    profile {
      token
      user {
        id
        ucinetid
        firstName
        lastName
        type
        isAdmin
        createdAt
        updatedAt
      }
    }
  }
`;

export function getProfileQuery () {
  return graphql({
    query: GQL_QUERY_PROFILE
  })
}

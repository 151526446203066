import { useQuery }                                          from '@apollo/client';
import AddIcon                                               from '@mui/icons-material/Add';
import DeleteIcon                                            from '@mui/icons-material/Delete';
import MoreVertIcon                                          from '@mui/icons-material/MoreVert';
import RemoveIcon                                            from '@mui/icons-material/Remove';
import VisibilityIcon                                        from '@mui/icons-material/Visibility';
// import { TimeslotEmailSkeleton } from './components/TimeslotEmailSkeleton';
import VisibilityOffIcon                                     from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  styled,
  Switch,
  TableCell,
  TableRow
}                                                            from '@mui/material';
import Button                                                from '@mui/material/Button';
import ButtonGroup                                           from '@mui/material/ButtonGroup';
import Divider                                               from '@mui/material/Divider';
import IconButton                                            from '@mui/material/IconButton';
import Link                                                  from '@mui/material/Link';
import List                                                  from '@mui/material/List';
import Typography                                            from '@mui/material/Typography';
import _addDays                                              from 'date-fns/addDays';
import dateIsEqual                                           from 'date-fns/isEqual';
import { useMemo, useState }      from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { graphql }                from '../data/client';
import { GQL_MUTATION_DELETE_TIMESLOT, GQL_MUTATION_SET_TIMESLOT, GQL_QUERY_GET_TIMESLOT } from '../data/timeslot';
import { useDialogCtrl }                                                                   from '../lib/Form';
import { MoreActionMenu }                                    from '../lib/menuElements';
import useSubmitSnack                                        from '../lib/snacks';
import _formatDate                                           from '../utils/formatDate';

const formatDate = (date, ...args) => date ? _formatDate(date, ...args) : '';

const WellList = styled(List)(({ theme }) => ({
  borderWidth:  1,
  borderColor:  theme.palette.divider,
  borderStyle:  'solid',
  borderRadius: theme.shape.borderRadius,
  padding:      0,

  '.MuiListItemButton-root:nth-child(even)': {
    backgroundColor: theme.palette.grey[100]
  }
}))


export function loadableProxy (object, loading) {
  return new Proxy({ ...object }, {
    get:
      (self, prop) => {
        if (prop === 'get') {
          return (func) => {
            return func(loading, self);
          }
        }

        if (loading) {
          return <Skeleton width="100%" />;
        }

        let s = self ?? {};

        // dig until no more nesting.
        // This will give {} in place of undefined and allow deep diving without
        // checking for middle objects.
        // ex:
        //    if looking for data['foo.bar.baz'] from data = { foo: {} }
        //    It will produce undefined, instead of erroring due to bar and baz === undefined.
        while (prop.indexOf('.') > -1) {
          const [ parent, child ] = prop.split(/\./);

          prop = child;
          s    = s[parent] ?? {};
        }

        return s[prop] ?? undefined;
      }
  });
}



export default function ViewTimeslot () {
  const [ seats, setSeats ]   = useState(0);
  const [ cutoff, setCutoff ] = useState(new Date());
  const [ hidden, setHidden ] = useState(false);
  const snacks                = useSubmitSnack();
  const navigate = useNavigate();
  const confirmDialogCtrl     = useDialogCtrl();

  const { id }                            = useParams();
  const { loading, error, data, refetch } = useQuery(GQL_QUERY_GET_TIMESLOT, {
    variables:   { id },
    onCompleted: ({ timeslot }) => {
      setSeats(timeslot.seats);
      setCutoff(timeslot.cutoff);
      setHidden(timeslot.hidden);
    }
  });

  const timeslot = useMemo(() => loadableProxy(data && data.timeslot, loading), [ data, loading ]);

  const students = timeslot.get((loading, { students }) => !loading && students ? students : []);
  const showSave = timeslot.get((
    loading,
    ts
  ) => !loading && (hidden !== ts.hidden || seats !== ts.seats || !dateIsEqual(cutoff, ts.cutoff)));

  const header = timeslot.get((loading, { start, end }) => {
    if (loading) {
      return <Skeleton />;
    }

    const startTime = formatDate(start, 'h:mm');
    const endTime   = formatDate(end, 'h:mm aa');
    const day       = formatDate(start, 'EEEE, MMMM do, yyyy')

    return `${day} ${startTime}-${endTime}`
  })


  const addDays = (date, numDays) => {
    const tmp = _addDays(date, numDays);
    return tmp >= timeslot.start ? date : tmp;
  }

  const handleSave = () => {
    snacks.start('Saving Changes');
    graphql({
      mutation:  GQL_MUTATION_SET_TIMESLOT,
      variables: {
        timeslot: {
          id,
          cutoff: cutoff,
          seats:  seats,
          hidden: hidden
        }
      }
    })
      .then(() => {
        snacks.success('Saved Changes. Refreshing')
        return refetch({ variables: { id } });
      })
      .catch(snacks.catchError)
    ;
  }

  const confirmDelete  = () => {
    confirmDialogCtrl.openDialog({
      title:    'Please Confirm',
      actions:  (
                  <Stack direction="row" spacing={1}>
                    <Button autoFocus onClick={confirmDialogCtrl.onClose} variant="contained" color={'primary'}>
                      Cancel
                    </Button>
                    <Button onClick={() => {
                      deleteTimeslot();
                      confirmDialogCtrl.onClose()
                    }} variant="outlined" color={'warning'}>
                      Delete
                    </Button>
                  </Stack>
                ),
      children: (
                  <Typography>
                    Please confirm. This will delete the timeslot and disassociate any students who've selected
                    it. <strong>Note:</strong> They will not receive an email confirming this deletion.
                  </Typography>
                )
    })
  }
  const deleteTimeslot = () => {
    snacks.start('Deleting Timeslot');
    graphql({
      mutation: GQL_MUTATION_DELETE_TIMESLOT,
      variables: { id }
    })
      .then(() => {
        snacks.success('Deleted from database');
        navigate('/timeslots');
      })
      .catch(snacks.catchError('Failed to delete'))
  }

  return (
    <>
      {confirmDialogCtrl.open ? confirmDialogCtrl.getDialog() : null}

      <Box display="flex">
        <Typography variant="h6" flexGrow={1} sx={{ pt: 0.5 }}>
          {header}
        </Typography>

        <MoreActionMenu
          actions={[
            { text: 'Delete', icon: <DeleteIcon />, onClick: confirmDelete },
          ]}
          anchorOrigin={{
            vertical:   'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical:   'top',
            horizontal: 'right',
          }}
        >
          <IconButton color="inherit">
            <MoreVertIcon />
          </IconButton>
        </MoreActionMenu>

      </Box>
      <Divider />

      <List>
        <ListItem>
          <ListItemText
            primary={timeslot.day}
            secondary="Day"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={timeslot.startTime}
            secondary="Start Time"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={timeslot.endTime}
            secondary="End Time"
          />
        </ListItem>
        <ListItem sx={{ display: 'flex' }}>
          <ListItemText
            primary={loading ? <Skeleton width="100%" /> : formatDate(cutoff, 'MM/dd/yyyy')}
            secondary="Cutoff Time"
            sx={{ flexGrow: 1 }}
          />
          <ButtonGroup variant="outlined">
            <Button onClick={() => setCutoff(c => addDays(c, -1))}>
              <RemoveIcon />
            </Button>
            <Button onClick={() => setCutoff(c => addDays(c, 1))}>
              <AddIcon />
            </Button>
          </ButtonGroup>
        </ListItem>
        {/*<ListItem>*/}
        {/*  <ListItemText primary={getVal('email')} secondary="Duration" />*/}
        {/*</ListItem>*/}
        <ListItem sx={{ display: 'flex' }}>
          <ListItemText primary={seats} secondary="Available Seats" sx={{ flexGrow: 1 }} />
          <ButtonGroup variant="outlined">
            <Button onClick={() => setSeats(s => s > 0 ? s - 1 : 0)}>
              <RemoveIcon />
            </Button>
            <Button onClick={() => setSeats(s => s + 1)}>
              <AddIcon />
            </Button>
          </ButtonGroup>
        </ListItem>
        <ListItem sx={{ display: 'flex' }}>
          <ListItemText primary={hidden ? 'Yes' : 'No'} secondary="Hidden From Students" sx={{ flexGrow: 1 }} />
          {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
          <Switch checked={hidden} onChange={e => setHidden(e.target.checked)} />
        </ListItem>
      </List>

      <Collapse in={showSave} sx={{ textAlign: 'right', px: 2 }}>
        <Button variant="contained" onClick={handleSave}>
          Save Changes
        </Button>
      </Collapse>



      <Typography variant="h6" sx={{ mt: 2 }}>
        Students
      </Typography>

      <WellList>
        {students.length ? students.map(s => (
          <ListItemButton key={s.id} component={Link} href={`/students/${s.id}`}>
            <ListItemText primary={`${s.firstName} ${s.lastName} <${s.email}>`}
                          secondary={`UCI #: ${s.uciStudentNum}`} />
          </ListItemButton>
        )) : (
           <ListItem>
             <ListItemText primary="No students yet" />
           </ListItem>
         )}
      </WellList>

    </>
  );
}



// function _ViewTimeslot () {
//   const [ seats, setSeats ]               = useState(0);
//   const [ cutoff, setCutoff ]             = useState(new Date());
//   const { id }                            = useParams();
//   const { loading, error, data, refetch } = useQuery(GQL_QUERY_GET_TIMESLOT, {
//     variables:   { id },
//     onCompleted: ({ timeslot }) => {
//       setSeats(timeslot.seats);
//       setCutoff(timeslot.cutoff);
//     }
//   });
//   const confirmDialog                     = useDialogCtrl({});
//   const snacks                            = useSubmitSnack();
//   const navigate                          = useNavigate();
//
//   const showSave = !loading && (seats !== data.timeslot.seats || !dateIsEqual(cutoff, data.timeslot.cutoff));
//
//   const timeslot = loading ? {} : data.timeslot;
//   if (!loading && !timeslot) {
//     return <NoTimeslot />;
//   }
//
//   const getVal  = (field, defaultVal = null) => {
//     if (loading || !timeslot) {
//       return <Skeleton width="100%" />;
//     }
//
//     let value = '';
//     if (field.indexOf('.') > -1) {
//       const [ left, right ] = field.split(/\./);
//       value                 = timeslot[left] && timeslot[left][right];
//     } else {
//       value = timeslot[field];
//     }
//
//     return value || defaultVal;
//   }
//   const getTime = (field, format, defaultVal = null) => {
//     if (loading || !timeslot) {
//       return <Skeleton width="100%" />;
//     }
//
//     let value = '';
//     if (field.indexOf('.') > -1) {
//       const [ left, right ] = field.split(/\./);
//       value                 = timeslot[left] && timeslot[left][right];
//     } else {
//       value = timeslot[field];
//     }
//
//     return formatDate(value, format, new Date()) || defaultVal;
//   }
//
//   const handleDelete = () => {
//     snacks.start('Removing Timeslot');
//     // deleteTimeslotMutation({ timeslot: timeslot.id })
//     //   .then(() => {
//     //     snacks.success('Removed Timeslot');
//     //     navigate('/timeslots');
//     //   })
//     //   .catch(snacks.catchError)
//     // ;
//   }
//
//   const handleDeleteConfirm = () => {
//     const onDelete = () => {
//       handleDelete();
//       confirmDialog.closeDialog();
//     }
//
//     confirmDialog.openDialog({
//       title:    'Are you sure?',
//       actions:  [
//         [
//           <Button key={0} onClick={onDelete} variant="outlined">
//             Remove Student
//           </Button>,
//           <Button key={1} autoFocus onClick={confirmDialog.closeDialog} variant="contained">
//             Cancel
//           </Button>
//         ]
//       ],
//       children: (
//                   <DialogContentText>
//                     Please confirm you wish to remove {timeslot.firstName} {timeslot.lastName} {'<'}{timeslot.email}>
//                   </DialogContentText>
//                 )
//     })
//   }
//
//   const addDays = (date, numDays) => {
//     const tmp = _addDays(date, numDays);
//     return tmp >= timeslot.start ? date : tmp;
//
//   }
//
//   const handleSave = () => {
//     snacks.start('Saving Changes');
//     // setTimeslotMutation({
//     //   id,
//     //   timeslot: {
//     //     start:  timeslot.start,
//     //     end:    timeslot.end,
//     //     cutoff: cutoff,
//     //     seats:  seats
//     //   }
//     // })
//     //   .then(() => {
//     //     snacks.success('Saved Changes. Refreshing')
//     //     return refetch({ variables: { id } });
//     //   })
//     //   .catch(snacks.catchError)
//     // ;
//   }
//
//   const now       = new Date();
//   const startTime = formatDate(timeslot.start, 'h:mm', now);
//   const endTime   = formatDate(timeslot.end, 'h:mm', now);
//   const day       = formatDate(timeslot.start, 'EEEE, MMMM Mo, yyyy', now)
//   const header    = `${day} ${startTime}-${endTime}`;
//
//   let students = [];
//   if (timeslot.students) {
//     students = [ ...timeslot.students ].sort((a, b) => {
//       if (a.lastName < b.lastName) return 1;
//       if (a.lastName > b.lastName) return -1;
//       if (a.firstName < b.firstName) return 1;
//       if (a.firstName > b.firstName) return -1;
//       return 0;
//     })
//   }
//
//   return (
//     <>
//       <Box display="flex">
//         <Typography variant="h6" flexGrow={1} sx={{ pt: 0.5 }}>
//           {loading ? <Skeleton /> : header}
//         </Typography>
//
//         <MoreActionMenu
//           actions={[
//             { text: 'Send Invite', icon: <EmailIcon />, onClick: noop },
//             { text: 'Edit', icon: <EditIcon />, component: Link, href: `/timeslots/${timeslot.id}/edit` },
//             { divider: true },
//             { text: 'Delete', icon: <DeleteIcon />, onClick: handleDeleteConfirm },
//           ]}
//           anchorOrigin={{
//             vertical:   'bottom',
//             horizontal: 'right',
//           }}
//           transformOrigin={{
//             vertical:   'top',
//             horizontal: 'right',
//           }}
//         >
//           <IconButton color="inherit">
//             <MoreVertIcon />
//           </IconButton>
//         </MoreActionMenu>
//
//       </Box>
//       <Divider />
//
//       <List>
//         <ListItem>
//           <ListItemText primary={getTime('start', 'EEEE, MMMM Mo hh:mm aaa')} secondary="Start Time" />
//         </ListItem>
//         <ListItem>
//           <ListItemText primary={getTime('end', 'EEEE, MMMM Mo hh:mm aaa')} secondary="End Time" />
//         </ListItem>
//         <ListItem sx={{ display: 'flex' }}>
//           <ListItemText primary={formatDate(cutoff, 'EEEE, MMMM Mo hh:mm aaa')} secondary="Cutoff Time"
//                         sx={{ flexGrow: 1 }} />
//           <ButtonGroup variant="outlined">
//             <Button onClick={() => setCutoff(c => addDays(c, -1))}>
//               <RemoveIcon />
//             </Button>
//             <Button onClick={() => setCutoff(c => addDays(c, 1))}>
//               <AddIcon />
//             </Button>
//           </ButtonGroup>
//         </ListItem>
//         {/*<ListItem>*/}
//         {/*  <ListItemText primary={getVal('email')} secondary="Duration" />*/}
//         {/*</ListItem>*/}
//         <ListItem sx={{ display: 'flex' }}>
//           <ListItemText primary={seats} secondary="Available Seats" sx={{ flexGrow: 1 }} />
//           <ButtonGroup variant="outlined">
//             <Button onClick={() => setSeats(s => s > 0 ? s - 1 : 0)}>
//               <RemoveIcon />
//             </Button>
//             <Button onClick={() => setSeats(s => s + 1)}>
//               <AddIcon />
//             </Button>
//           </ButtonGroup>
//         </ListItem>
//       </List>
//
//       <Collapse in={showSave} sx={{ textAlign: 'right', px: 2 }}>
//         <Button variant="contained" onClick={handleSave}>
//           Save Changes
//         </Button>
//       </Collapse>
//
//
//
//       <Typography variant="h6" sx={{ mt: 2 }}>
//         Students
//       </Typography>
//
//       <WellList>
//         {students.length ? students.map(s => (
//           <ListItemButton key={s.id} component={Link} href={`/students/${s.urlId}`}>
//             <ListItemText primary={`${s.firstName} ${s.lastName} <${s.email}>`} />
//           </ListItemButton>
//         )) : (
//            <ListItem>
//              <ListItemText primary="No students yet" />
//            </ListItem>
//          )}
//       </WellList>
//
//
//       {confirmDialog.open && confirmDialog.getDialog()}
//     </>
//   );
// }


function NoEmailPlaceholder () {
  return <TableRow>
    <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
      No emails yet
    </TableCell>
  </TableRow>
}
